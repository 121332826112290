import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeadingSmall, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import Divider from '../components/Divider'
import { Application } from './ApplicationsTable'
import C5 from '../assets/compliance/c5.png'
import CCPA from '../assets/compliance/ccpa.png'
import CIS from '../assets/compliance/cis.png'
import CPRA from '../assets/compliance/cpra.png'
import FedRamp from '../assets/compliance/fed_ramp.png'
import GDPR from '../assets/compliance/gdpr.png'
import HIPAA from '../assets/compliance/hipaa.png'
import HiTrustCSF from '../assets/compliance/hitrust-csf.png'
import IRAP from '../assets/compliance/irap.png'
import ISO26262 from '../assets/compliance/iso26262.png'
import ISO27001 from '../assets/compliance/iso27001.png'
import ISO27017 from '../assets/compliance/iso27017.png'
import ISO27018 from '../assets/compliance/iso27018.png'
import ISO27301 from '../assets/compliance/iso27301.png'
import ISO27701 from '../assets/compliance/iso27701.png'
import LGPD from '../assets/compliance/lgpd.png'
import NIST from '../assets/compliance/nist.png'
import PCIDSS from '../assets/compliance/pci_dss.png'
import SOC from '../assets/compliance/soc.png'
import SOC1 from '../assets/compliance/soc1.png'
import SOC2 from '../assets/compliance/soc2.png'
import SOC3 from '../assets/compliance/soc3.jpeg'
import StarLevelOne from '../assets/compliance/star_level_one.png'
import Tisax from '../assets/compliance/tisax.png'
import TxRamp from '../assets/compliance/tx_ramp.png'
import { Tooltip } from 'react-tooltip'
import { PurpleLink } from '../components/LoginComponents'
import OpenNewTab from '../assets/OpenNewTab'

const CertCard = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  width: '195px',
  height: '56px',
  padding: '0 12px',
})

const allCerts = [
  'c5',
  'ccpa',
  'cis',
  'cpra',
  'fedramp',
  'gdpr',
  'hipaa',
  'hitrust-csf',
  'irap',
  'iso26262',
  'iso27001',
  'iso27017',
  'iso27018',
  'iso27301',
  'iso27701',
  'lgpd',
  'nist',
  'pci_dss',
  'soc',
  'soc1',
  'soc1_type1',
  'soc1_type2',
  'soc2',
  'soc2_type1',
  'soc2_type2',
  'soc3',
  'star_level_one',
  'tisax',
  'tx_ramp',
]

type CertType = {
  name: string
  description: string
}

export const ComplianceCerts = ({ app }: { app: Application }) => {
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const [certs, setCerts] = useState<CertType[] | undefined>(undefined)
  const [securityURL, setSecurityURL] = useState<string | undefined>(undefined)

  useEffect(() => {
    const getCerts = async () => {
      const reqPath = addImpersonationParams(
        `/certs/${app.id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch compliance certificates', ToastType.ERROR)
        setCerts([])
      }
      setCerts(resp.certificates)
      setSecurityURL(resp.security_url)
    }
    getCerts()
  }, [app.id, impersonate, reqOrgId, reqUserId])

  if (!certs) {
    return null
  }

  return (
    <>
      <Divider />
      <HeadingSmall color="textDefault" style={{ margin: '24px 0 0 48px' }}>
        Compliance Certificates
        {certs.length && (
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {` (${certs.length || 0})`}
          </span>
        )}
      </HeadingSmall>
      {securityURL && (
        <PurpleLink
          href={securityURL}
          target="_blank"
          rel="noreferrer"
          style={{
            margin: '0px 48px',
            fontSize: '14px',
            textDecoration: 'none',
            fontWeight: '500',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <span style={{ paddingRight: '4px' }}>
            Learn more about {app.name}'s compliance certifications
          </span>
          <OpenNewTab />
        </PurpleLink>
      )}

      <div
        style={{
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
          margin: '14px 48px 24px',
        }}
      >
        {certs.map((cert, i) => (
          <CertCard key={i} className={`cert-${cert.name}`}>
            <img
              src={getCertLogo(cert.name)}
              alt={`${cert.name}-logo`}
              style={{
                width: '32px',
                height: '32px',
                marginRight: '12px',
                border: `1px solid ${theme.color.border}`,
                borderRadius: '4px',
                padding: '4px',
              }}
            />
            <HeadingSmallest color="textDefault">
              {cert.name.toUpperCase().replaceAll('_', ' ')}
            </HeadingSmallest>
          </CertCard>
        ))}
        {allCerts.map((cert, i) => (
          <Tooltip
            key={i}
            anchorSelect={`.cert-${cert}`}
            place="top"
            style={{ zIndex: 10, width: '350px' }}
          >
            {certs.find((c) => c.name === cert)?.description}
          </Tooltip>
        ))}
      </div>
    </>
  )
}

const getCertLogo = (cert: string) => {
  switch (cert) {
    case 'c5':
      return C5
    case 'ccpa':
      return CCPA
    case 'cis':
      return CIS
    case 'cpra':
      return CPRA
    case 'fedramp':
      return FedRamp
    case 'gdpr':
      return GDPR
    case 'hipaa':
      return HIPAA
    case 'hitrust-csf':
      return HiTrustCSF
    case 'irap':
      return IRAP
    case 'iso26262':
      return ISO26262
    case 'iso27001':
      return ISO27001
    case 'iso27017':
      return ISO27017
    case 'iso27018':
      return ISO27018
    case 'iso27301':
      return ISO27301
    case 'iso27701':
      return ISO27701
    case 'lgpd':
      return LGPD
    case 'nist':
      return NIST
    case 'pci_dss':
      return PCIDSS
    case 'soc':
      return SOC
    case 'soc1':
    case 'soc1_type1':
    case 'soc1_type2':
      return SOC1
    case 'soc2':
    case 'soc2_type1':
    case 'soc2_type2':
      return SOC2
    case 'soc3':
      return SOC3
    case 'star_level_one':
      return StarLevelOne
    case 'tisax':
      return Tisax
    case 'tx_ramp':
      return TxRamp
    default:
      return ''
  }
}
