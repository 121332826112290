import React, { CSSProperties } from 'react'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Error from '../assets/Error'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'

export const completeQuickbooksSetup = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const params = new URLSearchParams(
    window.location.href.substring(window.location.href.indexOf('?'))
  )
  // clear query string without refreshing the page. prevents double requests by state change
  window.history.replaceState({}, '', '/integrations')

  if (params.get('error')) {
    setModal(IntgModal.QuickbooksFail)
    return
  }

  const quickbooksPath = addImpersonationParams(
    '/integrations/quickbooks',
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(quickbooksPath, {
    method: 'POST',
    body: JSON.stringify({
      code: params.get('code'),
      realmId: params.get('realmId'),
      state: params.get('state'),
    }),
  })
  if (resp.error) {
    setModal(IntgModal.QuickbooksFail)
    return
  }
  setModal(IntgModal.QuickbooksSuccess)
}

const redirectToQuickbooksAuth = async () => {
  const clientID = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID
  const redirectURI = process.env.REACT_APP_QUICKBOOKS_REDIRECT_URI
  const state = 'test01'
  window.location.href = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientID}&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirectURI}&response_type=code&state=${state}&locale=en-us`
}

const removeQuickbooksIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const quickbooksPath = addImpersonationParams(
    '/integrations/quickbooks',
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(quickbooksPath, {
    method: 'DELETE',
  })
  if (resp.error) {
    setModal(IntgModal.QuickbooksFail)
    return
  }
  setModal(0)
}

export const QuickbooksModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  switch (modal) {
    case IntgModal.QuickbooksRedirect:
      return <QuickbooksRedirectModal onDismiss={onDismiss} />
    case IntgModal.QuickbooksSuccess:
      return <QuickbooksSuccessModal onDismiss={onDismiss} />
    case IntgModal.QuickbooksFail:
      return <QuickbooksFailModal onDismiss={onDismiss} />
    case IntgModal.QuickbooksRemove:
      return (
        <QuickbooksRemoveModal
          onDismiss={onDismiss}
          onClick={() => {
            removeQuickbooksIntegration(
              setModal,
              impersonate,
              reqOrgId,
              reqUserId
            )
          }}
        />
      )
    default:
      return <></>
  }
}

export const QuickbooksRedirectModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <QuickbooksModal
      onDismiss={onDismiss}
      onClick={redirectToQuickbooksAuth}
      canCancel={true}
      heading="Redirecting to Quickbooks"
      body={
        'You will be redirected to Quickbooks Online to complete the next step for integrating with Diminish.'
      }
      buttonTxt="Continue"
      color="textPurple"
    />
  )
}

export const QuickbooksSuccessModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <QuickbooksModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Congratulations"
      body={
        'You have successfully integrated with Quickbook Online. Spend data will be populated shortly.'
      }
      buttonTxt="Ok"
      Icon={GreenCheckCircle}
      color="successGreen"
    />
  )
}

export const QuickbooksFailModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <QuickbooksModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Something went wrong"
      body={'Please try again to integrate your account with Quickbook Online.'}
      buttonTxt="Ok"
      Icon={Error}
      color="errorRed"
    />
  )
}

export const QuickbooksRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <QuickbooksModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove this integration? By removing your Quickbook Online integration, SaaS spend data will be removed from the dashboard.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}

const QuickbooksModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '350px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
