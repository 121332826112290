import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Loading from '../../assets/Loading'
import Divider from '../../components/Divider'
import { TextField } from '../../components/InputFields'
import { Profile } from '../../components/Profile'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
} from '../../components/Text'
import { backendRequest } from '../../utils/utils'
import { ToastType, showToast } from '../../utils/toastify'

export const getFullName = (profile: UserProfile) => {
  if (profile.first_name.length === 0) {
    return profile.email
  }
  return `${profile.first_name} ${profile.last_name}`
}

const ProfileWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '425px',
})

export type UserProfile = {
  first_name: string
  last_name: string
  email: string
  profile_picture?: string
  company_name?: string
}

const ProfilePage = () => {
  const [profile, setProfile] = useState<UserProfile>()

  useEffect(() => {
    const getProfile = async () => {
      const resp = await backendRequest('/profile')
      if (resp.error) {
        showToast('Failed to fetch profile', ToastType.ERROR)
        setProfile(undefined)
      }
      setProfile(resp)
    }
    getProfile()
  }, [])

  if (profile === undefined) {
    return <Loading large />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '27px',
        padding: '0 110px',
      }}
    >
      <HeadingLarge>Profile</HeadingLarge>
      <Divider />
      <ProfileWrapper>
        <ProfileHeaderLarge profile={profile} />
        <TextField
          disabled
          label={'Full Name'}
          placeholder={getFullName(profile)}
          onChange={() => {}}
          marginBottom="34px"
        />
        <TextField
          disabled
          label={'Email'}
          placeholder={profile.email}
          onChange={() => {}}
          marginBottom="34px"
        />
        <TextField
          disabled
          label={'Password'}
          placeholder="***********"
          onChange={() => {}}
          marginBottom="34px"
        />
        <TextField
          disabled
          label={'Company'}
          placeholder={profile.company_name}
          onChange={() => {}}
          marginBottom="34px"
        />
      </ProfileWrapper>
    </div>
  )
}

export const ProfileHeaderLarge = ({ profile }: { profile: UserProfile }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '40px',
    }}
  >
    <Profile
      src={profile.profile_picture}
      size="large"
      name={getFullName(profile)}
    />
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <HeadingMedium color="textDefault">{getFullName(profile)}</HeadingMedium>
      <HeadingSmall>
        {profile.email === getFullName(profile) ? '' : profile.email}
      </HeadingSmall>
    </div>
  </div>
)

export default ProfilePage
