import React, { CSSProperties } from 'react'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Error from '../assets/Error'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'

export const completeXeroSetup = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const params = new URLSearchParams(
    window.location.href.substring(window.location.href.indexOf('?'))
  )
  // clear query string without refreshing the page. prevents double requests by state change
  window.history.replaceState({}, '', '/integrations')

  if (params.get('error')) {
    setModal(IntgModal.XeroFail)
    return
  }
  const xeroPath = addImpersonationParams(
    '/integrations/xero',
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(xeroPath, {
    method: 'POST',
    body: JSON.stringify({
      code: params.get('code'),
      state: params.get('state'),
    }),
  })
  if (resp.error) {
    setModal(IntgModal.XeroFail)
    return
  }
  setModal(IntgModal.XeroSuccess)
}

const redirectToXeroAuth = async () => {
  const clientID = process.env.REACT_APP_XERO_CLIENT_ID
  const redirectURI = process.env.REACT_APP_XERO_REDIRECT_URI
  const state = 'diminish_xero_35'
  window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&scope=offline_access openid profile email accounting.transactions.read accounting.contacts.read&state=${state}`
}

const removeXeroIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const xeroPath = addImpersonationParams(
    '/integrations/xero',
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )
  const resp = await backendRequest(xeroPath, { method: 'DELETE' })
  if (resp.error) {
    setModal(IntgModal.XeroFail)
    return
  }
  setModal(0)
}

export const XeroModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  switch (modal) {
    case IntgModal.XeroRedirect:
      return <XeroRedirectModal onDismiss={onDismiss} />
    case IntgModal.XeroSuccess:
      return <XeroSuccessModal onDismiss={onDismiss} />
    case IntgModal.XeroFail:
      return <XeroFailModal onDismiss={onDismiss} />
    case IntgModal.XeroRemove:
      return (
        <XeroRemoveModal
          onDismiss={onDismiss}
          onClick={() => {
            removeXeroIntegration(setModal, impersonate, reqOrgId, reqUserId)
          }}
        />
      )
    default:
      return <></>
  }
}

export const XeroRedirectModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <XeroModal
      onDismiss={onDismiss}
      onClick={redirectToXeroAuth}
      canCancel={true}
      heading="Redirecting to Xero"
      body={
        'You will be redirected to Xero to complete the next step for integrating with Diminish.'
      }
      buttonTxt="Continue"
      color="textPurple"
    />
  )
}

export const XeroSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <XeroModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Congratulations"
      body={
        'You have successfully integrated with Xero. Spend data will be populated shortly.'
      }
      buttonTxt="Ok"
      Icon={GreenCheckCircle}
      color="successGreen"
    />
  )
}

export const XeroFailModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <XeroModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Something went wrong"
      body={'Please try again later, or to contact support.'}
      buttonTxt="Ok"
      Icon={Error}
      color="errorRed"
    />
  )
}

export const XeroRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <XeroModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove this integration? By removing your Xero integration, SaaS spend data will be removed from the dashboard.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}

const XeroModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '350px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
