import styled, { CSSProperties } from 'styled-components'
import theme from '../Theme'

export const textStyle = {
  fontWeight: '700',
  lineHeight: '150%',
  margin: 0,
}

export type TextProps = {
  color?: keyof typeof theme.color
  inline?: boolean
  style?: CSSProperties
}

export const HeadingLarge = styled('h1')<TextProps>(
  textStyle,
  ({ color, inline }) => ({
    display: inline ? 'inline' : 'block',
    color: color ? theme.color[color] : theme.color.textDefault,
    fontSize: '24px',
    textTransform: 'capitalize',
  })
)

export const HeadingMedium = styled('h2')<TextProps>(
  textStyle,
  ({ color, inline }) => ({
    display: inline ? 'inline' : 'block',
    color: color ? theme.color[color] : theme.color.textSubdued,
    fontSize: '18px',
  })
)

export const HeadingSmall = styled('p')<TextProps>(
  textStyle,
  ({ color, inline }) => ({
    display: inline ? 'inline' : 'block',
    color: color ? theme.color[color] : theme.color.textSubdued,
    fontSize: '16px',
  })
)

export const HeadingSmallest = styled('p')<TextProps>(
  textStyle,
  ({ color, inline }) => ({
    fontWeight: 500,
    display: inline ? 'inline' : 'block',
    color: color ? theme.color[color] : theme.color.textDefault,
    fontSize: '14px',
  })
)

export const HelperText = styled(HeadingSmallest)({
  margin: '16px 0',
  maxWidth: '900px',
})
