import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import OpenNewTab from '../assets/OpenNewTab'
import GroupPeople from '../assets/GroupPeople'
import BarChart from '../assets/BarChart'
import Page from '../assets/Page'
import AccountBox from '../assets/AccountBox'
import AutoRenew from '../assets/AutoRenew'
import CalendarEvent from '../assets/CalendarEvent'

import theme from '../Theme'
import Button from '../components/Button'
import { IconWrapper } from '../components/Profile'
import Divider from '../components/Divider'
import {
  currencyFormat,
  getThisMonthShort,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { ProductDetailsTable } from './ProductDetailsTable'
import { SVGType } from '../assets/SVGWrapper'
import { useParams } from 'react-router-dom'
import { GraphWrapper, StyledDualBarChart } from './MainGraph'
import BackToPage from '../components/BackToPage'
import Loading from '../assets/Loading'
import BarChartEmpty from '../assets/BarChartEmpty'
import { LatestInvoicesTable } from './LatestInvoicesTable'
import useImpersonation from '../hooks/useImpersonation'
import { ContractModal } from '../Contracts/ContractModal'
import ArrowDropDown from '../assets/ArrowDropDown'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import DiminishIconDark from '../assets/DiminishIconDark'
import PlusCircle from '../assets/PlusCircle'
import Ball from '../assets/Ball'
import CloseX from '../assets/CloseX'
import { Tooltip } from 'react-tooltip'
import Modal, { ButtonWrapper } from '../components/Modal'
import { TextField } from '../components/InputFields'
import useAuth from '../hooks/useAuth'

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 20px;
`

const LogoWrapper = styled('div')({
  width: '72px',
  height: '72px',
  padding: '2px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  '& > img': {
    borderRadius: '4px',
    width: '72px',
    height: '72px',
  },
})

const TextIconWrapper = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
})

const ContractCardWrapper = styled('div')({
  display: 'flex',
  gap: '11px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '8px',
  backgroundColor: theme.color.white,
  padding: '16px 24px',
  marginBottom: '33px',
  flexGrow: 1,
})

export const ContractCard = ({
  Icon,
  title,
  value,
}: {
  Icon: SVGType
  title: JSX.Element | string
  value: JSX.Element | string
}) => (
  <ContractCardWrapper>
    <IconWrapper Icon={Icon} />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <HeadingSmall>{title}</HeadingSmall>
      <HeadingMedium color="textDefault">{value}</HeadingMedium>
    </div>
  </ContractCardWrapper>
)

export type ProductDetailsType = {
  id: number
  name: string
  category: string
  url: string
  logo_url: string
  num_users: number
  prev_num_users: number
  num_visits: number
  prev_num_visits: number
  cur_spend: number
  annual_spend: number
}

const ProductDetails = () => {
  const [contractModal, setContractModal] = useState(false)
  const [productDetails, setProductDetails] = useState<
    ProductDetailsType | null | undefined
  >(undefined)
  const { product_id } = useParams()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (product_id === undefined) {
      return
    }
    if (productDetails !== undefined) {
      return
    }

    const getApplication = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch product details', ToastType.ERROR)
        setProductDetails(null)
        return
      }
      setProductDetails(resp)
    }
    getApplication()
  }, [product_id, productDetails, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (productDetails) {
      document.title = `Diminish - ${productDetails.name}`
    }
  }, [productDetails])

  if (productDetails === undefined) {
    return <Loading large />
  }
  if (productDetails === null) {
    return (
      // TODO: implement better Not found page
      <>
        <BackToPage path={'/'} pageName={'Overview'} />
        <HeadingLarge style={{ marginTop: '20px', textTransform: 'none' }}>
          Sorry, the product you are looking could not be found
        </HeadingLarge>
      </>
    )
  }

  return (
    <>
      <BackToPage path={'/'} pageName={'Overview'} />
      <ProductHeader
        productDetails={productDetails}
        showContractModal={() => setContractModal(true)}
      />
      <ContractDetails />
      <SpendOverviewCards
        month_spend={productDetails.cur_spend}
        annual_spend={productDetails.annual_spend}
        active_users={productDetails.num_users}
        prev_active_users={productDetails.prev_num_users}
        total_visits={productDetails.num_visits}
        prev_total_visits={productDetails.prev_num_visits}
      />
      <div
        style={{
          display: 'flex',
          gap: '29px',
          marginTop: '24px',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <MainGraph
            product_id={product_id!}
            productName={productDetails.name}
          />
          <ProductDetailsTable />
          <LatestInvoicesTable />
        </div>
      </div>
      {contractModal && (
        <>
          <ContractModal
            onDismiss={() => setContractModal(false)}
            contractEdit={{
              application_name: productDetails.name,
              owner_email: '',
              plan_name: '',
              contract_value: 0,
              start_date: '',
              end_date: '',
              num_seats: 0,
              billing_cycle: '',
              document_type: [],
            }}
            modalType={undefined}
            contractEditCallback={() => {}}
          />
        </>
      )}
    </>
  )
}

type TrackingURLs = {
  tracking_urls: { tracking_url: string; status: string }[]
}

const ProductHeader = ({
  productDetails,
  showContractModal,
}: {
  productDetails: ProductDetailsType
  showContractModal: () => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showTrackingURL, setShowTrackingURL] = useState(false)
  const [trackingURLs, setTrackingURLs] = useState<
    TrackingURLs | null | undefined
  >(undefined)
  const [showAddTrackingURLModal, setShowAddTrackingURLModal] = useState(false)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  const { permissions } = useAuth()

  const { product_id } = useParams()
  useEffect(() => {
    if (trackingURLs !== undefined) {
      return
    }
    const getURLs = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}/tracking-url`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        setTrackingURLs(null)
        return
      }
      setTrackingURLs(resp)
    }
    getURLs()
  }, [trackingURLs, product_id, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        e.target !== ref.current &&
        !ref.current?.contains(e.target as Node)
      ) {
        setShowTrackingURL(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showTrackingURL])

  return (
    <HeadingWrapper
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
        <LogoWrapper>
          <img alt={productDetails.name} src={productDetails.logo_url} />
        </LogoWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <TextIconWrapper
            href={`https://${productDetails.url}`}
            target="_blank"
          >
            <HeadingLarge>{productDetails.name}</HeadingLarge>
            <OpenNewTab style={{ color: theme.color.textDefault }} />
          </TextIconWrapper>
          <HeadingMedium style={{ fontSize: '16px' }}>
            {productDetails.category}
          </HeadingMedium>
          {permissions?.see_tracking_urls && trackingURLs && (
            <>
              <TrackingURLSection
                ref={ref}
                onClick={() => setShowTrackingURL(!showTrackingURL)}
              >
                <HeadingSmallest>
                  ({trackingURLs.tracking_urls.length}) Tracking URL
                </HeadingSmallest>
                <ArrowDropDown style={{ height: '24px' }} />
              </TrackingURLSection>
              <div>
                {showTrackingURL && (
                  <TrackingURLWrapper>
                    {trackingURLs.tracking_urls.map((url, index) => (
                      <TrackingURLItem key={index}>
                        <HeadingSmallest
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {url.tracking_url}
                        </HeadingSmallest>
                        {url.status === 'diminish-managed' && (
                          <DiminishIconDark
                            className="diminish-managed"
                            style={{ height: '16px' }}
                          />
                        )}
                        {url.status === 'Active' && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                            }}
                          >
                            <GreenCheckCircle
                              className="active"
                              style={{ height: '20px' }}
                            />
                          </div>
                        )}
                        {url.status === 'Pending' && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              marginRight: '3px',
                            }}
                          >
                            <Ball
                              className="pending"
                              color={theme.color.warningYellow}
                              size={18}
                            />
                          </div>
                        )}
                        {url.status === 'Inactive' && (
                          <CloseX
                            className="inactive"
                            style={{
                              height: '16px',
                              fill: theme.color.errorRed,
                            }}
                          />
                        )}
                      </TrackingURLItem>
                    ))}
                    {permissions?.add_tracking_urls && (
                      <TrackingURLItem
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowAddTrackingURLModal(true)}
                      >
                        <HeadingSmallest color="textPurple">
                          Add New Tracking URL
                        </HeadingSmallest>
                        <PlusCircle
                          style={{ height: '16px', marginRight: '2px' }}
                        />
                      </TrackingURLItem>
                    )}
                    <Tooltip
                      anchorSelect=".diminish-managed"
                      place="right"
                      style={{ zIndex: 10, maxWidth: '250px' }}
                    >
                      This tracking URL is managed by Diminish. You cannot edit
                      or delete this URL.
                    </Tooltip>
                    <Tooltip
                      anchorSelect=".pending"
                      place="right"
                      style={{ zIndex: 10, maxWidth: '250px' }}
                    >
                      This tracking URL is in review. The Diminish team will
                      review and approve it soon.
                    </Tooltip>
                    <Tooltip
                      anchorSelect=".active"
                      place="right"
                      style={{ zIndex: 10, maxWidth: '250px' }}
                    >
                      This tracking URL is currently active.
                    </Tooltip>
                    <Tooltip
                      anchorSelect=".inactive"
                      place="right"
                      style={{ zIndex: 10, maxWidth: '250px' }}
                    >
                      This tracking URL is currently inactive. Usage data will
                      not be collected.
                    </Tooltip>
                  </TrackingURLWrapper>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button onClick={showContractModal}>Add contract</Button>
      </div>
      {permissions?.add_tracking_urls && showAddTrackingURLModal && (
        <AddTrackingURLModal
          onDismiss={() => {
            setShowAddTrackingURLModal(false)
            setTrackingURLs(undefined)
          }}
        />
      )}
    </HeadingWrapper>
  )
}

const TrackingURLSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const TrackingURLWrapper = styled('div')({
  position: 'absolute',
  display: 'flex',
  width: '300px',
  flexDirection: 'column',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  backgroundColor: theme.color.white,
  zIndex: 1,
})

const TrackingURLItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.color.border}`,
  padding: '8px',
})

const AddTrackingURLModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const [trackingURL, setTrackingURL] = useState('')
  const [loading, setLoading] = useState(false)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const validateURL = (url: string): boolean => {
    if (url !== trackingURL) {
      setTrackingURL(url)
    }

    const regex = // eslint-disable-next-line
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
    if (regex.test(url)) {
      return true
    }
    return false
  }

  const { product_id } = useParams()
  const handleSubmit = async () => {
    setLoading(true)
    const reqPath = addImpersonationParams(
      `/product/${product_id}/add-tracking-url`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )
    const resp = await backendRequest(reqPath, {
      method: 'POST',
      body: JSON.stringify({ tracking_url: trackingURL }),
    })
    setLoading(false)
    if (resp.error) {
      showToast('Failed to add tracking URL', ToastType.ERROR)
      return
    }
    showToast('Tracking URL added successfully', ToastType.SUCCESS)
    onDismiss()
  }

  return (
    <Modal style={{ width: '500px' }} onDismiss={onDismiss}>
      <HeadingMedium>Add new Tracking URL</HeadingMedium>
      <Divider />
      <HeadingSmallest style={{ margin: '20px 0' }}>
        Tracking URLs is how the Diminish Browser extension collects usage data.
        By adding a tracking URL to this application, future visits to this URL
        will be tracked <b>for all users in your organization</b>.
        <br />
        <br />
        Diminish will review your request before making it available to your
        entire organization.
      </HeadingSmallest>
      <TextField
        placeholder="Enter URL"
        label={''}
        onChange={(newInput) => validateURL(newInput)}
        marginBottom="20px"
      />
      <ButtonWrapper>
        <Button
          onClick={handleSubmit}
          disabled={!validateURL(trackingURL) || loading}
        >
          {loading ? <Loading /> : 'Submit'}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

type Contract = {
  plan_name: string
  billing_cycle: string
  pricing_model: string
  contract_value: number
  end_date: string
}

export const ContractDetails = () => {
  const [contractDetails, setContractDetails] = useState<Contract | undefined>()
  const { product_id } = useParams()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (contractDetails) {
      return
    }

    const getContract = async () => {
      const reqPath = addImpersonationParams(
        `/contract/${product_id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch contract details', ToastType.ERROR)
        return
      }
      setContractDetails(resp)
    }
    getContract()
  }, [contractDetails, product_id, reqOrgId, reqUserId, impersonate])

  if (!contractDetails) {
    return <></>
  }
  if (
    !contractDetails.plan_name &&
    !contractDetails.billing_cycle &&
    !contractDetails.pricing_model &&
    !contractDetails.contract_value &&
    !contractDetails.end_date
  ) {
    return <></>
  }

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <ContractCard
        Icon={Page}
        title={'Plan'}
        value={contractDetails.plan_name || '-'}
      />
      <ContractCard
        Icon={AccountBox}
        title={'Pricing Model'}
        value={contractDetails.pricing_model || '-'}
      />
      <ContractCard
        Icon={AutoRenew}
        title={'Billing Cycle'}
        value={contractDetails.billing_cycle || '-'}
      />
      <ContractCard
        Icon={AutoRenew}
        title={'Last Payment'}
        value={`$${currencyFormat(contractDetails.contract_value)}`}
      />
      <ContractCard
        Icon={CalendarEvent}
        title={'Renewal Date'}
        value={contractDetails.end_date || '-'}
      />
    </div>
  )
}

type GraphData = {
  [d: string]: {
    usage: number
    spend: number
    users: number
  }
}

const MainGraph = ({
  product_id,
  productName,
}: {
  product_id: string
  productName: string
}) => {
  const [dataset, setDataset] = useState<GraphData | null | undefined>(
    undefined
  )
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (dataset !== undefined) {
      return
    }

    const getUsage = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}/summary`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch usage/spend history', ToastType.ERROR)
        setDataset(null)
        return
      }
      if (Object.keys(resp.history).length === 0) {
        setDataset(null)
        return
      }
      setDataset(resp.history)
    }
    getUsage()
  }, [product_id, dataset, reqOrgId, reqUserId, impersonate])

  if (dataset === undefined) {
    return (
      <GraphWrapper>
        <Loading />
      </GraphWrapper>
    )
  }
  if (dataset === null) {
    return (
      <EmptyGraphWrapper>
        <BarChartEmpty />
        <HeadingMedium>
          No data found for {productName} at this moment
        </HeadingMedium>
      </EmptyGraphWrapper>
    )
  }

  const dataset1: { legend: string; data: { [s: string]: number } } = {
    legend: 'Usage',
    data: {},
  }
  const dataset2: { legend: string; data: { [s: string]: number } } = {
    legend: 'Spend',
    data: {},
  }
  const dataset3: { legend: string; data: { [s: string]: number } } = {
    legend: 'Users',
    data: {},
  }
  for (const [k, v] of Object.entries(dataset)) {
    dataset1.data[k] = v.usage
    dataset2.data[k] = v.spend
    dataset3.data[k] = v.users
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {dataset && Object.keys(dataset).length > 0 && (
        <StyledDualBarChart
          title="Usage/Spending Overview"
          subTitle="Last 12 months"
          dataset1={dataset1}
          dataset2={dataset2}
          dataset3={dataset3}
        />
      )}
    </div>
  )
}

const TotalSavingsWrapper = styled('div')({
  borderRadius: '8px',
  border: `1px solid ${theme.color.border}`,
  backgroundColor: theme.color.white,
  display: 'flex',
  alignItems: 'center',
  gap: '44px',
  padding: '24px',
  height: '75px',
  width: '100%',
})

const SpendOverviewCards = ({
  annual_spend,
  month_spend,
  active_users,
  prev_active_users,
  total_visits,
  prev_total_visits,
}: {
  annual_spend: number
  month_spend: number
  active_users: number
  prev_active_users: number
  total_visits: number
  prev_total_visits: number
}) => {
  const getUserDiff = (before: number, now: number) => {
    if (before < now) {
      return `(${Math.abs(before - now)} new user${
        now - before > 1 ? 's' : ''
      } this month)`
    }
    if (before > now) {
      return `(${Math.abs(before - now)} less user${
        before - now > 1 ? 's' : ''
      } this month)`
    }
    return '(same as last month)'
  }
  const getVisitsDiff = (before: number, now: number) => {
    if (before === 0) {
      return '(more visits this month)'
    }
    if (before < now) {
      return `(${Math.round((now / before - 1) * 100)}% more visit this month)`
    }
    if (before > now) {
      return `(${Math.round((1 - now / before) * 100)}% less visit this month)`
    }
    return '(same as last month)'
  }
  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <TotalSavingsWrapper style={{ flexGrow: 2, flexShrink: 2 }}>
        <div style={{ flexGrow: 3 }}>
          <HeadingSmall color={'textSubdued'}>
            This Month Spend{' '}
            <span style={{ fontSize: '14px', fontWeight: 500 }}>
              ({getThisMonthShort()})
            </span>
          </HeadingSmall>
          <HeadingLarge color={'textPurple'}>
            ${currencyFormat(month_spend)}
          </HeadingLarge>
        </div>

        <Divider vertical />
        <div style={{ flexGrow: 3 }}>
          <HeadingSmall color={'textSubdued'}>Annual Spend</HeadingSmall>
          <HeadingLarge color={'textDefault'}>
            ${currencyFormat(annual_spend)}
          </HeadingLarge>
        </div>
      </TotalSavingsWrapper>
      <TotalSavingsWrapper style={{ flexGrow: 1, flexShrink: 3 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            minWidth: '320px',
            flexGrow: 1,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <IconWrapper Icon={GroupPeople} />
              <HeadingSmall>Active Users</HeadingSmall>
            </div>
            <HeadingSmall color="textDefault" style={{ textAlign: 'right' }}>
              {active_users}{' '}
              <span
                style={{
                  color: theme.color.textSubdued,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {getUserDiff(prev_active_users, active_users)}
              </span>
            </HeadingSmall>
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <IconWrapper Icon={BarChart} />
              <HeadingSmall>Total Visits</HeadingSmall>
            </div>
            <HeadingSmall color="textDefault">
              {total_visits}{' '}
              <span
                style={{
                  color: theme.color.textSubdued,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {getVisitsDiff(prev_total_visits, total_visits)}
              </span>
            </HeadingSmall>
          </div>
        </div>
      </TotalSavingsWrapper>
    </div>
  )
}

const EmptyGraphWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.color.borderDisabled};
  border-radius: 8px;
  height: 414px;
  min-width: 803px;
  background-color: ${theme.color.white};
`

export default ProductDetails
