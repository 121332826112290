import { useCallback, useEffect, useState } from 'react'
import {
  HeadingMedium,
  HeadingSmallest,
  HelperText,
} from '../../components/Text'
import Loading from '../../assets/Loading'
import {
  currencyFormat,
  getMonthShort,
  backendRequest,
  getSortableDate,
  addImpersonationParams,
} from '../../utils/utils'
import { ToastType, showToast } from '../../utils/toastify'
import { Application } from '../../Overview/ApplicationsTable'
import {
  ApplicationCell,
  EmptyTableBody,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  StaticOwnerCell,
  RecommendationStatusCell,
  SortOrder,
  SortableHeader,
  SubTable,
  ArrowDownCell,
} from '../../components/Table'
import theme from '../../Theme'
import Modal, { ButtonWrapper } from '../../components/Modal'
import Divider from '../../components/Divider'
import Markdown from 'markdown-to-jsx'
import { TextField } from '../../components/InputFields'
import Button, { GhostButton, ButtonSubdued } from '../../components/Button'
import useImpersonation from '../../hooks/useImpersonation'
import styled from 'styled-components'
import DownloadIcon from '../../assets/Download'

const Counter = styled('span')({
  fontSize: '14px',
  fontWeight: 400,
})

export type UserActivityType = {
  [email: string]: string
}
type UpcomingChargesType = {
  upcoming_charges: Application[]
}

export const UserActivityTable = ({
  userActivity,
}: {
  userActivity: UserActivityType | undefined | null
}) => {
  return (
    <>
      <HeadingMedium style={{ margin: '33px 0 16px' }} color="textDefault">
        Workspace Inefficiency{' '}
        {userActivity && Object.keys(userActivity).length > 0 ? (
          <Counter>({Object.keys(userActivity).length})</Counter>
        ) : (
          <></>
        )}
      </HeadingMedium>
      <HelperText>
        This is a list of accounts on Google Workspace (or Microsoft 365) that
        haven't had a login in the past 2 weeks. This is usually an indication
        of accounts that can be suspended or converted to a group.{' '}
        <span style={{ fontWeight: 600 }}>
          Each account on this list costs around $200/year
        </span>
        .
      </HelperText>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>Email Address</TableCell>
            <TableCell>Last Login</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userActivity === undefined ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Loading style={{ margin: '24px' }} />
              </TableCell>
            </TableRow>
          ) : !userActivity || Object.keys(userActivity).length === 0 ? (
            <EmptyTableBody colSpan={2} />
          ) : (
            Object.entries(userActivity).map(([u, a], i) => (
              <TableRow key={i}>
                <StaticOwnerCell owner={u} />
                <TableCell>{a}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}

const HeadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '12px',
})

export const UpcomingChargesTable = ({
  upcomingCharges,
}: {
  upcomingCharges: UpcomingChargesType | null | undefined
}) => {
  const downloadUpcomingCharges = () => {
    if (!upcomingCharges) return
    const csv = upcomingCharges.upcoming_charges.map((uc) => {
      return `"${uc.name}","${uc.category}","${uc.app_owner}","${uc.spend_date}","$${currencyFormat(uc.spend_amount)}"`
    })
    csv.unshift(
      [
        'Application',
        'Category',
        'App Owner',
        'Next Transaction Date',
        'Last Payment Amount',
      ].join(',')
    )
    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-upcoming_charges-${new Date().toISOString()}.csv`
    a.click()
  }

  return (
    <>
      <HeadingWrapper>
        <HeadingMedium style={{ margin: '33px 0 16px' }} color="textDefault">
          Upcoming Charges{' '}
          {upcomingCharges && upcomingCharges.upcoming_charges.length > 0 ? (
            <Counter>({upcomingCharges.upcoming_charges.length})</Counter>
          ) : (
            <></>
          )}
        </HeadingMedium>
        <GhostButton
          onClick={downloadUpcomingCharges}
          style={{ padding: '12px' }}
        >
          <DownloadIcon />
        </GhostButton>
      </HeadingWrapper>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>Application</TableCell>
            <TableCell>Category</TableCell>
            <TableCell w="200px">Owner</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Last Payment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {upcomingCharges === undefined ? (
            <TableRow style={{ width: '100%', height: '50px' }}>
              <TableCell colSpan={5}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : !upcomingCharges ||
            !upcomingCharges.upcoming_charges ||
            upcomingCharges.upcoming_charges.length === 0 ? (
            <EmptyTableBody colSpan={5} />
          ) : (
            upcomingCharges.upcoming_charges.map((a, i) => (
              <TableRow key={i}>
                <ApplicationCell id={a.id} logo={a.logo} name={a.name} />
                <TableCell>{a.category}</TableCell>
                <StaticOwnerCell owner={a.app_owner} />
                <TableCell>{a.spend_date}</TableCell>
                <TableCell>${currencyFormat(a.spend_amount)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}

enum TableSort {
  APPLICATION = 'APPLICATION',
  CATEGORY = 'CATEGORY',
  AMOUNT = 'AMOUNT',
  MONTH = 'MONTH',
  STATUS = 'STATUS',
}

export type SavingsOpportunitiesType = {
  recommendations: RecommendationType[]
}
type RecommendationType = {
  id: number
  app: {
    id: number
    name: string
    logo: string
  }
  title: string
  markdown_description: string
  recommendation_type: string
  recommendation_status: string
  savings_amount_high: number
  savings_amount_low: number
  savings_realized: number
  report_month: string
  rejection_reason: string
  instruction_url: string
  updated_by: {
    first_name: string
    last_name: string
    email: string
    profile_picture: string
  } | null
}

export const SavingsOpportunitiesTable = ({
  savingsOpportunities,
}: {
  savingsOpportunities: SavingsOpportunitiesType | null | undefined
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [modalInfo, setModalInfo] = useState<RecommendationType | undefined>()
  const [sortAttr, setSortAttr] = useState<TableSort>(TableSort.AMOUNT)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC)
  const [savingsRecommendations, setSavingsRecommendations] = useState<
    undefined | null | RecommendationType[]
  >(undefined)

  const rejectionModalClickCallback = (recommendationID: number) => {
    if (!savingsRecommendations) return
    const recommendation = savingsRecommendations.find(
      (r) => r.id === recommendationID
    )
    if (recommendation) {
      setModalInfo(recommendation)
      setShowRejectionModal(true)
    }
  }
  const onRejectRecommendation = (recommendationID: number) => {
    if (!savingsRecommendations) return
    const recommendation = savingsRecommendations.find(
      (r) => r.id === recommendationID
    )
    if (recommendation) {
      recommendation.recommendation_status = 'Rejected'
      setSavingsRecommendations([...savingsRecommendations, recommendation])
    }
  }

  const sort = useCallback(() => {
    if (!savingsRecommendations) {
      return []
    }
    const sorted = [...savingsRecommendations].reverse()
    switch (sortAttr) {
      case TableSort.APPLICATION:
        sorted.sort((a, b) => a.app.name.localeCompare(b.app.name))
        break
      case TableSort.CATEGORY:
        sorted.sort((a, b) => a.app.name.localeCompare(b.app.name))
        sorted.sort((a, b) =>
          a.recommendation_type.localeCompare(b.recommendation_type)
        )
        break
      case TableSort.AMOUNT:
        sorted.sort((a, b) => a.app.name.localeCompare(b.app.name))
        sorted.sort((a, b) => a.savings_amount_low - b.savings_amount_low)
        break
      case TableSort.STATUS:
        sorted.sort((a, b) => a.app.name.localeCompare(b.app.name))
        sorted.sort((a, b) => sortStatus(a, b))
        break
      case TableSort.MONTH:
      default:
        sorted.sort((a, b) => a.app.name.localeCompare(b.app.name))
        sorted.sort((a, b) =>
          getSortableDate(a.report_month).localeCompare(
            getSortableDate(b.report_month)
          )
        )
    }
    sortOrder === SortOrder.DESC && sorted.reverse()
    return sorted
  }, [sortAttr, sortOrder, savingsRecommendations])

  useEffect(() => {
    setSavingsRecommendations(sort())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAttr, sortOrder, savingsOpportunities])

  useEffect(() => {
    if (!savingsOpportunities) {
      setSavingsRecommendations(savingsOpportunities)
      return
    }
    setSavingsRecommendations(savingsOpportunities.recommendations)
  }, [savingsOpportunities])

  const onSortChange = (selected: TableSort) => {
    if (!savingsRecommendations) return
    if (sortAttr === selected) {
      setSortOrder(sortOrder * -1)
    } else {
      setSortOrder(SortOrder.ASC)
      setSortAttr(selected)
    }
  }

  const sortStatus = (a: RecommendationType, b: RecommendationType) => {
    const sortOrder: { [x: string]: number } = {
      'To Do': 0,
      'In Review': 1,
      Done: 2,
      Rejected: 3,
    }
    return (
      sortOrder[a.recommendation_status] - sortOrder[b.recommendation_status]
    )
  }

  return (
    <>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell mw="5px"></TableCell>
            <SortableHeader
              isSorted={sortAttr === TableSort.APPLICATION}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.APPLICATION)}
            >
              Application
            </SortableHeader>
            <TableCell>Title</TableCell>
            <SortableHeader
              isSorted={sortAttr === TableSort.CATEGORY}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.CATEGORY)}
            >
              Savings Category
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.AMOUNT}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.AMOUNT)}
            >
              Spend Inefficiency
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.MONTH}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.MONTH)}
            >
              Report Month
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.STATUS}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.STATUS)}
            >
              Status
            </SortableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {savingsRecommendations === undefined ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : savingsRecommendations === null ||
            savingsRecommendations.length === 0 ? (
            <EmptyTableBody colSpan={7} />
          ) : (
            savingsRecommendations.map((r, i) => (
              <SavingsOpportunitiesTableRow
                key={i}
                r={r}
                rejectionModalClickCallback={() =>
                  rejectionModalClickCallback(r.id)
                }
              />
            ))
          )}
        </TableBody>
      </TableWrapper>
      {showModal && modalInfo ? (
        <InfoModal
          title={modalInfo.title}
          markdown_description={modalInfo.markdown_description}
          setShowModal={setShowModal}
        />
      ) : showRejectionModal && modalInfo ? (
        <RecommendationRejectModal
          recommendationID={modalInfo.id}
          setShowRejectionModal={setShowRejectionModal}
          onRejectSuccess={onRejectRecommendation}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const SavingsOpportunitiesTableRow = ({
  r,
  rejectionModalClickCallback,
}: {
  r: RecommendationType
  rejectionModalClickCallback: () => void
}) => {
  const [showChildren, setShowChildren] = useState(false)

  return (
    <>
      <TableRow>
        <ArrowDownCell
          onClick={() => setShowChildren(!showChildren)}
          open={showChildren}
        />
        <ApplicationCell id={r.app.id} name={r.app.name} logo={r.app.logo} />
        <TableCell>{r.title}</TableCell>
        <TableCell>{r.recommendation_type}</TableCell>
        <TableCell>
          {r.savings_amount_low === r.savings_amount_high ? (
            <>${currencyFormat(r.savings_amount_low)}</>
          ) : (
            <>
              ${currencyFormat(r.savings_amount_low)} — $
              {currencyFormat(r.savings_amount_high)}
            </>
          )}
        </TableCell>
        <TableCell>{getMonthShort(r.report_month)}</TableCell>
        <RecommendationStatusCell
          key={r.id}
          recommendationID={r.id}
          onReject={rejectionModalClickCallback}
        >
          {r.recommendation_status}
        </RecommendationStatusCell>
      </TableRow>
      {showChildren && (
        <RecommendationSubRow
          title={r.title}
          description={r.markdown_description}
          rejection_reason={r.rejection_reason}
          instructionLink={r.instruction_url}
        />
      )}
    </>
  )
}

const RecommendationSubRow = ({
  title,
  description,
  rejection_reason,
  instructionLink,
}: {
  title: string
  description: string
  rejection_reason: string
  instructionLink: string
}) => {
  const onClick = () => {
    window.open(instructionLink, '_blank')
  }

  return (
    <SubTable>
      <TableCell colSpan={7} style={{ padding: '24px 48px' }}>
        <div
          style={{
            display: 'flex',
            gap: '42px',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%' }}>
            <HeadingMedium
              color="textDefault"
              style={{ display: 'block', paddingBottom: '12px' }}
            >
              Savings Details {title && `— ${title}`}
            </HeadingMedium>
            <Markdown>{description}</Markdown>
          </div>
          {rejection_reason && (
            <div style={{ width: '100%' }}>
              <HeadingMedium
                color="textDefault"
                style={{ display: 'block', paddingBottom: '12px' }}
              >
                Rejection Reason
              </HeadingMedium>
              {rejection_reason}
            </div>
          )}
        </div>
        {instructionLink && (
          <ButtonWrapper>
            <ButtonSubdued onClick={onClick}>
              Click here to see instructions
            </ButtonSubdued>
          </ButtonWrapper>
        )}
      </TableCell>
    </SubTable>
  )
}

const InfoModal = ({
  title,
  markdown_description,
  setShowModal,
}: {
  title: string
  markdown_description: string
  setShowModal: (show: boolean) => void
}) => {
  return (
    <Modal
      onDismiss={() => setShowModal(false)}
      style={{ width: '460px', borderColor: theme.color.textPurple }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">{title}</HeadingMedium>
        </div>
        <Divider />
        <div>
          <Markdown>{markdown_description}</Markdown>
        </div>
      </div>
    </Modal>
  )
}

const RecommendationRejectModal = ({
  recommendationID,
  setShowRejectionModal,
  onRejectSuccess,
}: {
  recommendationID: number
  setShowRejectionModal: (show: boolean) => void
  onRejectSuccess: (recommendationID: number) => void
}) => {
  const [rejectionReason, setRejectionReason] = useState('')
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onReject = async () => {
    const reportPath = addImpersonationParams(
      '/report/savings_opportunities',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const response = await backendRequest(reportPath, {
      method: 'POST',
      body: JSON.stringify({
        new_status: 'rejected',
        recommendation_id: recommendationID,
        rejection_reason: rejectionReason,
      }),
    })
    if (response.error) {
      showToast('Failed to reject saving opportunity', ToastType.ERROR)
      return
    }
    onRejectSuccess(recommendationID)
    setShowRejectionModal(false)
  }

  return (
    <Modal
      onDismiss={() => setShowRejectionModal(false)}
      style={{ width: '460px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Rejecting Saving Opportunity
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest style={{ marginBottom: '10px' }}>
          We value your input! Before you reject this saving opportunity, could
          you please share your reasons with us? Your feedback helps us refine
          our suggestions and provide you with more relevant options in the
          future.
        </HeadingSmallest>
        <TextField
          marginBottom="40px"
          label={''}
          placeholder="Please share your reason here"
          onChange={(newInput: string) => setRejectionReason(newInput)}
        />
        <Divider />
        <ButtonWrapper>
          <GhostButton
            style={{ borderColor: theme.color.errorRed }}
            onClick={() => setShowRejectionModal(false)}
          >
            Cancel
          </GhostButton>
          <Button
            disabled={rejectionReason === ''}
            style={{
              backgroundColor: `${
                rejectionReason === ''
                  ? theme.color.btnBgDisabled
                  : theme.color.errorRed
              }`,
              borderColor: `${
                rejectionReason === ''
                  ? theme.color.btnBgDisabled
                  : theme.color.errorRed
              }`,
            }}
            onClick={onReject}
          >
            Reject
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
