import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button, { GhostButton } from '../components/Button'
import Dropdown from '../components/Dropdown'
import { FilteredTextField, TextField } from '../components/InputFields'
import Modal, { ButtonWrapper } from '../components/Modal'
import {
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import { getFullName, UserProfile } from '../pages/Profile'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import theme from '../Theme'
import { FileAdded, Uploader } from '../components/FileUploader'
import Loading from '../assets/Loading'
import Divider from '../components/Divider'

export type ContractEdit = {
  contract_id?: number
  application_name: string
  owner_email: string
  plan_name: string
  contract_value: number
  start_date: string
  end_date: string
  num_seats: number
  billing_cycle: string
  document_type: string[]
}

const FormWrapper = styled('div')({
  display: 'flex',
  gap: '20px',
  marginTop: '24px',
})

const FormColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'stretch',
})

const BadgeWrapper = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  width: 365px;
  gap: 8px;
  align-items: center;
  margin-bottom: 3px;
  min-height: 25px;
`
export const Badge = styled('div')<{
  disabled?: boolean
  onClick?: () => void
}>`
  display: inline-flex;
  align-items: center;
  height: 25px;
  border-radius: 12px;
  padding: 0 12px;
  font-size: 16px;
  background-color: ${({ disabled }) =>
    disabled
      ? theme.color.backgroundSubdued
      : theme.color.successGreenBackground};
  color: ${({ disabled }) =>
    disabled ? theme.color.textSubdued : theme.color.textSuccess};
  cursor: ${({ disabled, onClick }) =>
    disabled || !onClick ? 'default' : 'pointer'};
`

const ModalOption = styled('div')<{ selected?: boolean }>`
  border: 1px solid
    ${({ selected }) =>
      selected ? theme.color.textPurple : theme.color.border};
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0 16px;
  cursor: pointer;
`

export enum ContractModalType {
  CREATE = 'create',
  EDIT = 'edit',
}

export const ContractModal = ({
  onDismiss,
  contractEdit,
  modalType,
  contractEditCallback,
}: {
  onDismiss: () => void
  contractEdit?: ContractEdit | null
  modalType?: ContractModalType
  contractEditCallback: (contract: ContractEdit) => void
}) => {
  const [createMethod, setCreateMethod] = useState<string | undefined>()
  const [step, setStep] = useState<number>(1)
  const [uploadSuccess, setUploadSuccess] = useState<boolean | undefined>(
    undefined
  )

  const onUploadSuccess = (success: boolean) => {
    setUploadSuccess(success)
    setStep(3)
  }

  if (modalType) {
    return (
      <ContractModalManual
        onDismiss={onDismiss}
        contractEdit={contractEdit}
        modalType={modalType}
        contractEditCallback={contractEditCallback}
      />
    )
  }

  if (step === 2) {
    switch (createMethod) {
      case 'upload':
        return (
          <ContractModalUpload
            onDismiss={onDismiss}
            onComplete={onUploadSuccess}
          />
        )
      case 'manual':
        return (
          <ContractModalManual
            onDismiss={onDismiss}
            contractEdit={contractEdit}
            modalType={ContractModalType.CREATE}
            contractEditCallback={contractEditCallback}
          />
        )
    }
  }

  if (step === 3) {
    return (
      <Modal onDismiss={onDismiss} style={{ width: '400px' }}>
        <HeadingMedium color="textDefault">
          {uploadSuccess ? 'Upload Successful' : 'Failed to upload'}
        </HeadingMedium>
        <Divider style={{ margin: '16px 0' }} />
        <HeadingSmallest>
          {uploadSuccess
            ? 'Files successfully uploaded! Your data will be processed in the next 48h.'
            : 'Please reach out to support for assistance.'}
        </HeadingSmallest>
        <Divider style={{ margin: '16px 0' }} />
        <ButtonWrapper>
          <Button
            color={uploadSuccess ? 'successGreen' : 'errorRed'}
            onClick={onDismiss}
            style={{ width: '100px' }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </Modal>
    )
  }

  return (
    <Modal onDismiss={onDismiss} style={{ width: '750px' }}>
      <HeadingMedium color="textDefault">Add a Contract</HeadingMedium>
      <HeadingSmallest>
        Select one of the options below to continue
      </HeadingSmallest>
      <ModalOption
        selected={createMethod === 'upload'}
        onClick={() => setCreateMethod('upload')}
      >
        <HeadingSmall color="textDefault">Upload Contract</HeadingSmall>
        <HeadingSmallest>
          Simplify adding contracts by submitting documents for automated
          processing.
        </HeadingSmallest>
      </ModalOption>
      <ModalOption
        selected={createMethod === 'manual'}
        onClick={() => setCreateMethod('manual')}
      >
        <HeadingSmall color="textDefault">Add a Contract Manually</HeadingSmall>
        <HeadingSmallest>
          Manually input contract details to create a new entry.
        </HeadingSmallest>
      </ModalOption>
      <ButtonWrapper>
        <GhostButton onClick={onDismiss}>Cancel</GhostButton>
        <Button disabled={!createMethod} onClick={() => setStep(2)}>
          Continue
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const ContractModalUpload = ({
  onDismiss,
  onComplete,
}: {
  onDismiss: () => void
  onComplete: (success: boolean) => void
}) => {
  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState(false)

  const filesToUpload = async (fs: File[]) => {
    const fileReadPromises = files.map((file) => {
      return new Promise<{ file_name: string; content: string }>(
        (resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            if (e.target?.result) {
              resolve({
                file_name: file.name,
                content: e.target.result as string,
              })
            } else {
              reject(new Error('File reading failed'))
            }
          }
          reader.onerror = reject
          reader.readAsDataURL(file)
        }
      )
    })

    // Wait for all files to be read
    const results = await Promise.all(fileReadPromises)
    return results
  }

  const handleSubmit = () => {
    setLoading(true)
    const submitContract = async () => {
      const doc_payload = await filesToUpload(files)

      const resp = await backendRequest('/contracts/upload', {
        method: 'POST',
        body: JSON.stringify({ contracts: doc_payload }),
      })
      if (resp.error) {
        onComplete(false)
        return
      }
      onComplete(true)
    }
    submitContract()
  }

  return (
    <Modal onDismiss={onDismiss} style={{ width: '750px' }}>
      <HeadingMedium color="textDefault">Upload Contract</HeadingMedium>
      <HeadingSmallest>
        Upload PDF files only, with max size of 15Mb.
      </HeadingSmallest>
      <Uploader
        disabled={false}
        multiple={true}
        handleChange={(file: File | FileList) => {
          if (file instanceof FileList) {
            setFiles([...files, ...Array.from(file)])
            return
          }
          setFiles([...files, file])
        }}
        fileTypes={['PDF']}
      />
      <div style={{ maxHeight: '255px', overflow: 'scroll' }}>
        {files.map((file, i) => (
          <FileAdded
            key={i}
            file={file}
            onRemove={(file: File) =>
              setFiles((prevFiles) => prevFiles.filter((f) => f !== file))
            }
          />
        ))}
      </div>
      <ButtonWrapper>
        <GhostButton onClick={onDismiss}>Cancel</GhostButton>
        <Button disabled={!files.length || loading} onClick={handleSubmit}>
          {loading ? <Loading /> : 'Upload Contract'}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const ContractModalManual = ({
  onDismiss,
  contractEdit,
  modalType,
  contractEditCallback,
}: {
  onDismiss: () => void
  contractEdit?: ContractEdit | null
  modalType: ContractModalType
  contractEditCallback: (contract: ContractEdit) => void
}) => {
  const [contractValue, setContractValue] = useState(
    contractEdit?.contract_value.toString() || '0'
  )
  const [formData, setFormData] = useState<ContractEdit>(
    contractEdit || {
      application_name: '',
      owner_email: '',
      plan_name: '',
      contract_value: 0,
      start_date: '',
      end_date: '',
      num_seats: 0,
      billing_cycle: '',
      document_type: [],
    }
  )
  const [productNames, setProductNames] = useState<string[] | undefined>()
  const [users, setUsers] = useState<UserProfile[] | undefined>()
  const [errorMsg, setError] = useState('')
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    const getProductNames = async () => {
      const reqPath = addImpersonationParams(
        '/products',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch products', ToastType.ERROR)
        return
      }
      setProductNames(resp.sort())
    }
    getProductNames()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        '/user_names',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          'Failed to fetch users. Please try again later.',
          ToastType.ERROR
        )
        return
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  const handleSubmit = () => {
    const submitContract = async () => {
      let path = '/contracts'
      if (contractEdit && contractEdit.contract_id) {
        path = `/contracts/${contractEdit.contract_id}`
      }
      const contractsPath = addImpersonationParams(
        path,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(contractsPath, {
        method: 'POST',
        body: JSON.stringify(formData),
      })
      if (resp.error) {
        if (path === '/contracts') {
          showToast('Failed to create contract', ToastType.ERROR)
        } else {
          showToast('Failed to update contract', ToastType.ERROR)
        }
        setError(resp.error)
        return
      }
      if (path === '/contracts') {
        showToast('Successfully created new contract', ToastType.SUCCESS)
      } else {
        showToast('Successfully updated contract', ToastType.SUCCESS)
      }
      contractEditCallback(formData)
      setError('')
      onDismiss()
    }
    submitContract()
  }

  const handleOwnerSelection = (value: string) => {
    if (!users) return
    for (const u of users) {
      if (getFullName(u) === value) {
        setFormData((prevState) => ({
          ...prevState,
          owner_email: u.email,
        }))
        return
      }
    }
  }
  const handleChange = (value: string, name: string) => {
    if (name === 'contract_value' && isNaN(Number(value))) {
      setError('Contract value must be a number')
      return
    }
    if (name === 'num_seats' && isNaN(Number(value))) {
      setError('Number of seats must be a number')
      return
    }
    if (name === 'num_seats' && Number(value) < 0) {
      setError('Number of seats must be a positive number')
      return
    }
    if (name === 'contract_value' && Number(value) < 0) {
      setError('Contract value must be a positive number')
      return
    }
    setError('')
    if (name === 'contract_value') {
      setContractValue(value)
    }
    if (name === 'contract_value' || name === 'num_seats') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: Number(value),
      }))
      return
    }
    if (name === 'document_type') {
      setFormData((prevState) => {
        if (prevState.document_type.includes(value)) return prevState
        prevState.document_type.push(value)
        return {
          ...prevState,
        }
      })
      return
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onBadgeClick = (value: string) => {
    setFormData((prevState) => {
      prevState.document_type = prevState.document_type.filter(
        (dt) => dt !== value
      )
      return {
        ...prevState,
      }
    })
  }

  const shouldDisableSubmit = () => {
    return (
      !formData.application_name ||
      !formData.plan_name ||
      formData.contract_value <= 0
    )
  }

  return (
    <Modal onDismiss={onDismiss} style={{ width: '750px' }}>
      <HeadingMedium color="textDefault">
        {modalType === ContractModalType.CREATE
          ? 'Add a Contract'
          : 'Edit Contract'}
      </HeadingMedium>
      <FilteredTextField
        value={formData.application_name}
        label="Application*"
        placeholder="Search Applications"
        options={productNames || []}
        onSelect={(s) => handleChange(s, 'application_name')}
        style={{ marginTop: '16px' }}
      />
      <FormWrapper>
        <FormColumn>
          <Dropdown
            label="Document Type"
            placeholder="Select document type"
            onSelect={(v) => handleChange(v, 'document_type')}
            options={['Invoice/Receipt', 'MSA/TOU', 'DPA', '(M)NDA', 'InfoSec']}
            marginBottom="4px"
          />

          <BadgeWrapper>
            {formData.document_type.map((dt, i) => (
              <Badge key={i} onClick={() => onBadgeClick(dt)}>
                {dt}
              </Badge>
            ))}
          </BadgeWrapper>

          <TextField
            value={formData.plan_name}
            onChange={(v) => handleChange(v, 'plan_name')}
            label="Plan Name*"
            placeholder="Enter plan name here"
            marginBottom="32px"
          />
          <TextField
            value={formData.start_date}
            onChange={(v) => handleChange(v, 'start_date')}
            type="date"
            label="Contract Start Date"
            placeholder="Select a date"
            marginBottom="32px"
          />
          <TextField
            value={formData.num_seats.toString()}
            onChange={(v) => handleChange(v, 'num_seats')}
            label="Number of Seats"
            placeholder="Enter number of seats here"
            marginBottom="32px"
          />
        </FormColumn>
        <FormColumn>
          <Dropdown
            defaultValue={formData.owner_email}
            label="Owner"
            placeholder="Select an option"
            onSelect={(selection) => handleOwnerSelection(selection)}
            options={
              users?.map((u: UserProfile) => getFullName(u)).sort() || []
            }
            marginBottom="32px"
            style={{ width: '365px' }}
          />
          <TextField
            value={contractValue}
            onChange={(v) => handleChange(v, 'contract_value')}
            label="Contract Value*"
            placeholder="Enter contract value here"
            marginBottom="32px"
          />
          <TextField
            value={formData.end_date}
            onChange={(v) => handleChange(v, 'end_date')}
            type="date"
            label="Contract End Date"
            placeholder="Select a date"
            marginBottom="32px"
          />
          <Dropdown
            defaultValue={formData.billing_cycle}
            label="Billing Cycle"
            placeholder="Select an option"
            onSelect={(s) => handleChange(s, 'billing_cycle')}
            options={['Monthly', 'Quarterly', 'Annually']}
            marginBottom="32px"
          />
        </FormColumn>
      </FormWrapper>
      {errorMsg.length > 0 && (
        <HeadingSmallest color="errorRed">{errorMsg}</HeadingSmallest>
      )}
      <ButtonWrapper>
        <GhostButton onClick={onDismiss}>Cancel</GhostButton>
        <Button onClick={handleSubmit} disabled={shouldDisableSubmit()}>
          {modalType === ContractModalType.CREATE ? 'Create' : 'Save'}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
