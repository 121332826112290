import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Loading from '../../assets/Loading'
import Divider from '../../components/Divider'
import { TextField } from '../../components/InputFields'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmallest,
} from '../../components/Text'
import { backendRequest, addImpersonationParams } from '../../utils/utils'
import { ToastType, showToast } from '../../utils/toastify'
import Button, { GhostButton } from '../../components/Button'
import theme from '../../Theme'
import Modal from '../../components/Modal'
import Error from '../../assets/Error'
import useImpersonation from '../../hooks/useImpersonation'

const SettingsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const SettingsPage = () => {
  return <DangerZone />
}

enum PseudonymizationStatus {
  NotStarted = 'not_started',
  Requested = 'requested',
  Approved = 'approved',
  Completed = 'completed',
}

const DangerZone = () => {
  return <PseudonymizationSection />
}

const PseudonymizationSection = () => {
  const [pseudonymizationStatus, setPseudonymizationStatus] =
    useState<PseudonymizationStatus>()
  const [showPseudonymizeModal, setShowPseudonymizeModal] = useState(false)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    const getSettings = async () => {
      const reqPath = addImpersonationParams(
        '/org/pseudonymize',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)

      if (resp.error) {
        setPseudonymizationStatus(undefined)
      }
      setPseudonymizationStatus(resp.status)
    }
    getSettings()
  }, [reqOrgId, reqUserId, impersonate])

  const getSettings = async () => {
    setShowPseudonymizeModal(false)
    const reqPath = addImpersonationParams(
      '/org/pseudonymize',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath)
    if (resp.error) {
      setPseudonymizationStatus(undefined)
    }
    setPseudonymizationStatus(resp.status)
  }

  const onCancelPseudonymize = async () => {
    const pseudonymizePath = addImpersonationParams(
      '/org/pseudonymize',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    let resp = await backendRequest(pseudonymizePath, {
      method: 'POST',
      body: JSON.stringify({ status: PseudonymizationStatus.NotStarted }),
    })

    if (resp.error) {
      showToast(
        'Failed to cancel data deletion. Please contact support',
        ToastType.ERROR
      )
      return
    }

    resp = await backendRequest(pseudonymizePath)
    getSettings()
    showToast(
      'You have successfully cancelled the data deletion request',
      ToastType.SUCCESS
    )
  }

  const onRequestPseudonymize = async () => {
    showToast(
      'You have successfully requested an org-wide data deletion',
      ToastType.WARNING
    )
    getSettings()
  }

  if (pseudonymizationStatus === undefined) {
    return <Loading large />
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '27px',
        padding: '0 110px',
      }}
    >
      <HeadingLarge>Organization Settings</HeadingLarge>
      <Divider />
      <SettingsWrapper>
        <HeadingMedium color="textDefault">Danger Zone</HeadingMedium>
        <HeadingSmallest>
          This will remove all the names and related data of your entire
          organization from our system. This is a destructive operation and it
          cannot be reverted.
        </HeadingSmallest>
        {pseudonymizationStatus === PseudonymizationStatus.NotStarted ? (
          <Button
            onClick={() => setShowPseudonymizeModal(true)}
            style={{
              backgroundColor: theme.color.errorRed,
              borderColor: theme.color.errorRed,
              width: '200px',
              marginTop: '24px',
            }}
          >
            Remove all user data
          </Button>
        ) : (
          <></>
        )}
        {pseudonymizationStatus === PseudonymizationStatus.Requested ||
        pseudonymizationStatus === PseudonymizationStatus.Approved ? (
          <>
            <HeadingSmallest color="errorRed" style={{ marginTop: '24px' }}>
              Your request is being processed and all your user data will be
              removed within the next 72 hours. You can still cancel this
              operation by clicking the button below.
            </HeadingSmallest>
            <Button
              onClick={onCancelPseudonymize}
              style={{
                backgroundColor: theme.color.errorRed,
                borderColor: theme.color.errorRed,
                width: '250px',
                marginTop: '24px',
              }}
            >
              Cancel user data removal
            </Button>
          </>
        ) : (
          <></>
        )}
        {pseudonymizationStatus === PseudonymizationStatus.Completed ? (
          <>
            {/* Note: Customers shouldn't be able to see this state but just in case... */}
            <HeadingSmallest>
              All your organization personal data has been successfully removed.
              You will not be able to login to Diminish in the future.
            </HeadingSmallest>
            <Button
              disabled
              style={{
                width: '250px',
                marginTop: '24px',
              }}
            >
              Remove all user data
            </Button>
          </>
        ) : (
          <></>
        )}
      </SettingsWrapper>
      {showPseudonymizeModal && (
        <PseudonymizeModal
          onDismiss={() => setShowPseudonymizeModal(false)}
          onClickCallback={onRequestPseudonymize}
        />
      )}
    </div>
  )
}

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  marginTop: '16px',
})

const PseudonymizeModal = ({
  onDismiss,
  onClickCallback,
}: {
  onDismiss: () => void
  onClickCallback: () => void
}) => {
  const [userConfirmation, setUserConfirmation] = useState('')
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const onPseudonymize = async () => {
    const reqPath = addImpersonationParams(
      '/org/pseudonymize',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath, {
      method: 'POST',
      body: JSON.stringify({ status: PseudonymizationStatus.Requested }),
    })

    if (resp.error) {
      showToast(
        'Failed to request data deletion. Please contact support',
        ToastType.ERROR
      )
      return
    }
    onClickCallback()
  }

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '400px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Error style={{ height: '24px', width: '24px' }} />
          <HeadingMedium color="textDefault">Danger</HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          Are you sure you want to delete your organization from Diminish? You
          are about to do some dangerous thing here. To confirm, please type in
          "<span style={{ fontWeight: 700 }}>remove all user data</span>" below.
        </HeadingSmallest>
        <TextField
          label={'Confirmation'}
          type="text"
          name="confirmation"
          placeholder={'remove all user data'}
          onChange={(newInput) => setUserConfirmation(newInput)}
        />
        <Divider />
        <ButtonWrapper>
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color.errorRed }}
          >
            Cancel
          </GhostButton>
          {userConfirmation !== 'remove all user data' ? (
            <Button disabled>Confirm</Button>
          ) : (
            <Button
              onClick={onPseudonymize}
              style={{
                backgroundColor: theme.color.errorRed,
                borderColor: theme.color.errorRed,
              }}
            >
              Confirm
            </Button>
          )}
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

export default SettingsPage
