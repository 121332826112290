import styled, { CSSProperties } from 'styled-components'
import Divider from '../../components/Divider'
import { HeadingLarge } from '../../components/Text'
import React, { useState, useEffect, useRef } from 'react'
import { backendRequest } from '../../utils/utils'
import { Select, OptionsWrapper, Option } from '../../components/Dropdown'
import theme from '../../Theme'
import ArrowDropDown from '../../assets/ArrowDropDown'
import useImpersonation from '../../hooks/useImpersonation'
import { ToastType, showToast } from '../../utils/toastify'

const ImpersonationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '425px',
  gap: '27px',
})

export type Organization = {
  id: number
  name: string
}

const ImpersonationPage = () => {
  return <ImpersonationSection />
}

const OrganizationDropdown = ({
  label,
  placeholder,
  orgs,
  selectedOrg,
  onSelect,
  disabled,
  marginBottom,
  style,
}: {
  label?: string
  placeholder?: string
  orgs: Organization[]
  selectedOrg: number
  onSelect: (org_id: number, org_name: string) => void
  disabled?: boolean
  marginBottom?: string
  style?: CSSProperties
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Organization | null>(
    orgs.find((org) => org.id === selectedOrg) || null
  )

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  useEffect(() => {
    setSelectedOption(orgs.find((org) => org.id === selectedOrg) || null)
  }, [selectedOrg, orgs])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: marginBottom,
        ...style,
      }}
    >
      <label
        style={{
          color: disabled ? theme.color.textSubdued : theme.color.textDefault,
          fontSize: '12px',
        }}
        onClick={() => setShowOptions(!showOptions)}
      >
        {label}
      </label>
      <Select
        aria-label={label}
        disabled={disabled}
        ref={ref}
        isOpen={showOptions}
        onClick={() => !disabled && setShowOptions(!showOptions)}
      >
        {selectedOption ? (
          selectedOption.name
        ) : (
          <span style={{ color: theme.color.textSubdued }}>{placeholder}</span>
        )}
        {!disabled && <ArrowDropDown />}
      </Select>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {orgs.map((org) => (
              <Option
                key={org.id}
                onClick={() => {
                  setSelectedOption(org)
                  setShowOptions(false)
                  onSelect(org.id, org.name)
                }}
              >
                {org.name}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </div>
  )
}

export type OrgUser = {
  id: number
  email: string
  first_name: string
  last_name: string
}

const OrgUserDropdown = ({
  label,
  placeholder,
  users,
  selectedUser,
  onSelect,
  disabled,
  marginBottom,
  style,
}: {
  label?: string
  placeholder?: string
  users: OrgUser[]
  selectedUser: number
  onSelect: (user_id: number, user_email: string) => void
  disabled?: boolean
  marginBottom?: string
  style?: CSSProperties
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<OrgUser | null>(
    users.find((user) => user.id === selectedUser) || null
  )

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  useEffect(() => {
    setSelectedOption(users.find((user) => user.id === selectedUser) || null)
  }, [selectedUser, users])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: marginBottom,
        ...style,
      }}
    >
      <label
        style={{
          color: disabled ? theme.color.textSubdued : theme.color.textDefault,
          fontSize: '12px',
        }}
        onClick={() => setShowOptions(!showOptions)}
      >
        {label}
      </label>
      <Select
        aria-label={label}
        disabled={disabled}
        ref={ref}
        isOpen={showOptions}
        onClick={() => !disabled && setShowOptions(!showOptions)}
      >
        {selectedOption ? (
          selectedOption.email
        ) : (
          <span style={{ color: theme.color.textSubdued }}>{placeholder}</span>
        )}
        {!disabled && <ArrowDropDown />}
      </Select>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {users.map((user) => (
              <Option
                key={user.id}
                onClick={() => {
                  setSelectedOption(user)
                  setShowOptions(false)
                  onSelect(user.id, user.email)
                }}
              >
                {user.email}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </div>
  )
}

const ImpersonateInput = styled('input')({
  width: '20px',
  height: '20px',
  marginRight: '10px',
  cursor: 'pointer',
})

const ImpersonateLabel = styled('label')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const ImpersonationSection = () => {
  const [orgs, setOrgs] = useState<Organization[] | undefined>(undefined)
  const [users, setUsers] = useState<OrgUser[] | undefined>(undefined)

  const {
    reqOrgId,
    setReqOrgId,
    impersonate,
    setImpersonate,
    setReqOrgName,
    reqUserId,
    setReqUserId,
    setReqUserEmail,
  } = useImpersonation()

  const onSelectOrg = (orgId: number, orgName: string) => {
    setReqOrgId(orgId)
    setReqOrgName(orgName)
    getUsers(orgId)
  }

  const onSelectUser = (userId: number, userEmail: string) => {
    setReqUserId(userId)
    setReqUserEmail(userEmail)
  }

  const impersonateChecked = () => {
    if (reqOrgId === -1) {
      showToast('Please select an organization', ToastType.ERROR)
      return
    }

    if (reqUserId === -1) {
      showToast('Please select a user', ToastType.ERROR)
      return
    }

    if (impersonate) {
      setReqOrgId(-1)
      setReqUserId(-1)
      setReqOrgName('')
      setReqUserEmail('')
      setImpersonate(!impersonate)
      showToast('Stopped impersonating', ToastType.SUCCESS)
      return
    }

    setImpersonate(!impersonate)
    showToast('Starting impersonating', ToastType.SUCCESS)
  }

  useEffect(() => {
    const getOrgs = async () => {
      const orgsRes = await backendRequest('/impersonation/orgs')
      if (orgsRes.error) {
        showToast(
          'Failed to fetch organizations for impersonation',
          ToastType.ERROR
        )
        setOrgs([])
      } else {
        setOrgs(orgsRes)
      }
    }
    getOrgs()
  }, [reqOrgId, impersonate])

  const getUsers = async (orgId: number) => {
    const orgUsersRes = await backendRequest(
      `/impersonation/org_users/${orgId}`
    )

    if (orgUsersRes.error) {
      showToast('Failed to fetch users for impersonation', ToastType.ERROR)
      setUsers([])
    } else {
      setUsers(orgUsersRes)
    }
  }

  useEffect(() => {
    if (reqOrgId !== -1) {
      getUsers(reqOrgId)
    }
  }, [reqOrgId, impersonate])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '27px',
        padding: '0 110px',
      }}
    >
      <HeadingLarge>Impersonate Organization</HeadingLarge>
      <Divider />
      <ImpersonationWrapper>
        <OrganizationDropdown
          label="Organization"
          placeholder="Select an organization"
          orgs={orgs || []}
          selectedOrg={reqOrgId}
          onSelect={onSelectOrg}
        />

        <OrgUserDropdown
          label="User"
          placeholder="Select a user"
          users={users || []}
          selectedUser={reqUserId}
          onSelect={onSelectUser}
        />

        <div style={{ display: 'flex' }}>
          <ImpersonateInput
            id="input"
            type="checkbox"
            defaultChecked={impersonate}
            onClick={impersonateChecked}
            disabled={reqOrgId === -1 || reqUserId === -1}
          />
          <ImpersonateLabel htmlFor="input">Impersonate</ImpersonateLabel>
        </div>
      </ImpersonationWrapper>
    </div>
  )
}

export default ImpersonationPage
