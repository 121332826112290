import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { FileAdded, Uploader } from '../components/FileUploader'
import CSV from '../assets/CSV'
import {
  DropdownCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from '../components/Table'
import ArrowDropDown from '../assets/ArrowDropDown'
import { Option, OptionsWrapper } from '../components/Dropdown'
import { Profile } from '../components/Profile'
import { addImpersonationParams, backendRequest } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'
import Loading from '../assets/Loading'

enum HRISStep {
  ImportFile = 0,
  MapFields,
  ReviewData,
  Success,
  Failure,
}

export const HRISModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<HRISStep>(HRISStep.ImportFile)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [csvData, setCsvData] = useState<string[][]>([])
  const [rows, setRows] = useState<ColumnMap[]>([])
  const [fileColumn, setFileColumn] = useState<ColumnMap>({
    email: '',
    first_name: '',
    last_name: '',
    department: '',
    title: '',
  })

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (file) {
      csvFileTo2DArray(file).then((data) => setCsvData(data as string[][]))
    }
  }, [file])

  useEffect(() => {
    if (csvData.length > 1) {
      const header = csvData[0]
      const data = csvData.slice(1)
      const r = data.map((row) => {
        const obj: ColumnMap = {
          email: row[header.indexOf(fileColumn.email)],
          first_name: row[header.indexOf(fileColumn.first_name)],
          last_name: row[header.indexOf(fileColumn.last_name)],
          department: row[header.indexOf(fileColumn.department)],
          title: row[header.indexOf(fileColumn.title)],
        }
        return obj
      })
      setRows(r)
    }
  }, [fileColumn, csvData])

  const csvFileTo2DArray = async (file: File): Promise<string[][]> => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const text = event.target?.result as string

        const rows = text.trim().split('\n')
        const result = rows.map((row) => {
          const regex = /("([^"]*)")|([^,]+)/g
          return Array.from(row.matchAll(regex)).map(
            (match) => match[2] || match[3]
          )
        })
        resolve(result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsText(file)
    })
  }

  const onComplete = async () => {
    setLoading(true)
    const createUpdateUsers = addImpersonationParams(
      '/users/import',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(createUpdateUsers, {
      method: 'POST',
      body: JSON.stringify(rows),
    })
    setLoading(false)
    if (resp.error) {
      setStep(HRISStep.Failure)
      return
    }
    setStep(HRISStep.Success)
  }

  const onModalDismiss = () => {
    setFile(undefined)
    setStep(HRISStep.ImportFile)
    setCsvData([])
    setFileColumn({
      email: '',
      first_name: '',
      last_name: '',
      department: '',
      title: '',
    })
    onDismiss()
  }

  if (modal !== IntgModal.HRISCSVImporter) return <></>

  switch (step) {
    case HRISStep.ImportFile:
      return (
        <HRISModal
          onDismiss={onModalDismiss}
          onClick={() => setStep(HRISStep.MapFields)}
          heading="HRIS Importer"
          body={<Step1 file={file} setFile={setFile} />}
          buttonTxt="Continue"
          disableBtn={!file}
          loading={loading}
        />
      )
    case HRISStep.MapFields:
      return (
        <HRISModal
          onDismiss={onModalDismiss}
          onClick={() => setStep(HRISStep.ReviewData)}
          heading="HRIS Importer"
          body={
            <Step2
              file={file}
              header={csvData[0]}
              fileColumn={fileColumn}
              setFileColumn={setFileColumn}
            />
          }
          buttonTxt="Continue"
          disableBtn={fileColumn.email === ''}
          loading={loading}
        />
      )
    case HRISStep.ReviewData:
      return (
        <HRISModal
          onDismiss={onModalDismiss}
          onClick={() => onComplete()}
          heading="HRIS Importer"
          body={<Step3 file={file} rows={rows} />}
          buttonTxt="Continue"
          loading={loading}
        />
      )
    case HRISStep.Success:
      return <SuccessModal onDismiss={onModalDismiss} />
    case HRISStep.Failure:
      return <FailureModal onDismiss={onModalDismiss} />
    default:
      return <></>
  }
}

const HRISModal = ({
  onDismiss,
  onClick,
  heading,
  body,
  buttonTxt,
  disableBtn,
  loading,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  heading: string
  body: ReactNode
  buttonTxt: string
  disableBtn?: boolean
  loading: boolean
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '750px', borderColor: theme.color.textPurple }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      {body}
      <Divider />
      <ButtonWrapper>
        <GhostButton
          onClick={onDismiss}
          style={{ padding: '14px 24px', borderColor: theme.color.textPurple }}
        >
          Cancel
        </GhostButton>
        {loading ? (
          <Button onClick={onClick} disabled={true}>
            <Loading />
          </Button>
        ) : (
          <Button onClick={onClick} disabled={disableBtn}>
            {buttonTxt}
          </Button>
        )}
      </ButtonWrapper>
    </div>
  </Modal>
)

const Step1 = ({
  file,
  setFile,
}: {
  file: File | undefined
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
}) => {
  return (
    <div>
      <HeadingSmallest>
        Follow these steps to import employee data into the system.
      </HeadingSmallest>
      <HeadingSmallest style={{ margin: '12px 0' }}>
        <span style={{ fontWeight: 600, color: theme.color.errorRed }}>
          Important Note:
        </span>{' '}
        The Email Address field is required and essential for linking data with
        existing users. Only the fields Email Address, First Name, Last Name,
        Department, and Title will be processed. Any additional data fields in
        the CSV file will not be used. Follow these steps to import employee
        data into the system. Please note that the Email Address field is
        required to proceed with the import process.
      </HeadingSmallest>
      <HeadingMedium color="textDefault">How It Works:</HeadingMedium>
      <ol
        style={{
          fontWeight: 500,
          color: theme.color.textDefault,
          fontSize: '14px',
        }}
      >
        <li>Upload your CSV file</li>
        <li>Match Your Data Columns</li>
        <li>Review and validate the data</li>
      </ol>
      <HeadingMedium color="textDefault">1. Upload CSV File</HeadingMedium>
      <HeadingSmallest>
        Upload your CSV file to begin the import.
      </HeadingSmallest>
      <Uploader
        disabled={!!file}
        multiple={false}
        handleChange={(f) => setFile(f)}
        fileTypes={['CSV']}
      />
      {file ? (
        <FileAdded file={file} onRemove={(file: File) => setFile(undefined)} />
      ) : (
        <></>
      )}
    </div>
  )
}

type ColumnMap = {
  email: string
  first_name: string
  last_name: string
  department: string
  title: string
}

const Step2 = ({
  file,
  header,
  fileColumn,
  setFileColumn,
}: {
  file: File | undefined
  header: string[]
  fileColumn: ColumnMap
  setFileColumn: React.Dispatch<React.SetStateAction<ColumnMap>>
}) => {
  return (
    <div>
      {file ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '18px',
          }}
        >
          <HeadingSmallest>Selected File:</HeadingSmallest>
          <CSV style={{ height: '24px' }} />
          <HeadingSmallest>
            {file.name} - {file.size} bytes
          </HeadingSmallest>
        </div>
      ) : (
        <></>
      )}

      <HeadingMedium color="textDefault">
        2. Match Your Data Columns
      </HeadingMedium>
      <HeadingSmallest style={{ margin: '8px 0' }}>
        Match the columns in your CSV file with the fields in our system.
      </HeadingSmallest>
      <HeadingSmallest style={{ marginBottom: '24px' }}>
        On the left, you'll see our system's fields. On the right, you'll see
        the columns from your CSV. Use the dropdown menus to select which CSV
        column corresponds to each system field.
      </HeadingSmallest>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>System Fields</TableCell>
            <TableCell>Columns from your File</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Email<span style={{ color: theme.color.errorRed }}>*</span>
            </TableCell>
            <FileColDropdown
              ops={header}
              onSelect={(opt) => setFileColumn({ ...fileColumn, email: opt })}
            />
          </TableRow>
          <TableRow>
            <TableCell>First Name</TableCell>
            <FileColDropdown
              ops={header}
              onSelect={(opt) =>
                setFileColumn({ ...fileColumn, first_name: opt })
              }
            />
          </TableRow>
          <TableRow>
            <TableCell>Last Name</TableCell>
            <FileColDropdown
              ops={header}
              onSelect={(opt) =>
                setFileColumn({ ...fileColumn, last_name: opt })
              }
            />
          </TableRow>
          <TableRow>
            <TableCell>Department</TableCell>
            <FileColDropdown
              ops={header}
              onSelect={(opt) =>
                setFileColumn({ ...fileColumn, department: opt })
              }
            />
          </TableRow>
          <TableRow>
            <TableCell>Job Title</TableCell>
            <FileColDropdown
              ops={header}
              onSelect={(opt) => setFileColumn({ ...fileColumn, title: opt })}
            />
          </TableRow>
        </TableBody>
      </TableWrapper>
    </div>
  )
}

const Step3 = ({
  file,
  rows,
}: {
  file: File | undefined
  rows: ColumnMap[]
}) => {
  return (
    <div>
      {file ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '18px',
          }}
        >
          <HeadingSmallest>Selected File:</HeadingSmallest>
          <CSV style={{ height: '24px' }} />
          <HeadingSmallest>
            {file.name} - {file.size} bytes
          </HeadingSmallest>
        </div>
      ) : (
        <></>
      )}

      <HeadingMedium color="textDefault">
        3. Review and Validate Data
      </HeadingMedium>
      <HeadingSmallest style={{ margin: '8px 0' }}>
        Please ensure that your data is correctly aligned and ready for import.
        Check that all fields are correctly mapped to the columns in your CSV
        file.
      </HeadingSmallest>
      <HeadingSmallest style={{ marginBottom: '24px' }}>
        This view shows a sample of the data for your review. The entire dataset
        will be processed once the mapping is confirmed.
      </HeadingSmallest>
      <div style={{ maxHeight: '345px', overflow: 'auto' }}>
        <TableWrapper cellSpacing={0}>
          <TableHead style={{ position: 'sticky', top: 0 }}>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <UserCell
                  email={row.email}
                  first_name={row.first_name}
                  last_name={row.last_name}
                />
                <TableCell>{row.department || '-'}</TableCell>
                <TableCell>{row.title || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </div>
    </div>
  )
}

const UserCell = ({
  email,
  first_name,
  last_name,
}: {
  email: string
  first_name: string
  last_name: string
}) => {
  return (
    <TableCell
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      {first_name ? (
        <>
          <Profile size="small" name={first_name} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {`${first_name || ''} ${last_name || ''}`}
            <span style={{ fontSize: '14px', color: theme.color.textSubdued }}>
              {email}
            </span>
          </div>
        </>
      ) : (
        <>
          <Profile size="small" name={email} />
          {email}
        </>
      )}
    </TableCell>
  )
}

const FileColDropdown = ({
  ops,
  onSelect,
}: {
  ops: string[]
  onSelect: (opt: string) => void
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [options, setOptions] = useState<string[]>(
    ops.toSorted((a, b) => a.localeCompare(b))
  )
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  useEffect(() => {
    setOptions(options)
  }, [options])

  return (
    <TableCell>
      <DropdownCell
        ref={ref}
        isOpen={showOptions}
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedOption ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {selectedOption}
          </span>
        ) : (
          <span>Select column from file</span>
        )}
        <ArrowDropDown />
      </DropdownCell>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper style={{ height: '200px' }}>
            {options.map((o, i) => (
              <Option
                key={i}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => {
                  setSelectedOption(o)
                  setShowOptions(false)
                  onSelect(o)
                }}
              >
                {o}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </TableCell>
  )
}

const SuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">Import Successful</HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          Your records are now updated in the system.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
const FailureModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Something Went Wrong
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          There was an issue while importing your data. Please contact support.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.errorRed,
              borderColor: theme.color.errorRed,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
