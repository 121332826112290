import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Loading from '../assets/Loading'
import OverviewTableIcon from '../assets/OverviewTableIcon'
import Button, { GhostButton } from '../components/Button'
import Dropdown, { Option, OptionsWrapper } from '../components/Dropdown'
import {
  ApplicationCell,
  ArrowDownCell,
  DepartmentCell,
  OwnerCell,
  SliderCell,
  SortOrder,
  SortableHeader,
  SubTable,
  SubTableCell,
  SubTableHead,
  SubTableHeading,
  SubTableRow,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
} from '../components/Table'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { UserType } from '../Users'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
  getRangeBudget,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { getUserName } from '../utils/users'
import PlusCircle from '../assets/PlusCircle'
import MinusCircle from '../assets/MinusCircle'
import DownloadIcon from '../assets/Download'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ComplianceCerts } from './ComplianceCerts'
import { GetDefaultDateOptions } from '../utils/dates'
import CloseX from '../assets/CloseX'
import KeyboardArrowRight from '../assets/KeyboardArrowRight'
import KeyboardArrowLeft from '../assets/KeyboardArrowLeft'
import TooManyFiltersTableIcon from '../assets/TooManyFiltersTableIcon'

const downloadOverview = (
  apps: Application[] | undefined,
  budgets: Budget[] | undefined,
  dateFilter: string
) => {
  if (!apps) return
  const csv = apps.map((app) => {
    if (budgets && budgets.length > 0) {
      const budget = budgets.find((b) => b.saas_tool_id === app.id)
      const monthlyBudget = getRangeBudget(dateFilter, budget)
      return `"${app.name}","${app.department}","${app.category}","${app.pricing_model}","${app.url}","${app.app_owner}","${app.num_users}","${new Intl.NumberFormat().format(app.num_visits)}","$${currencyFormat(app.spend_amount)}","${app.spend_date}","$${currencyFormat(monthlyBudget)}"`
    }
    return `"${app.name}","${app.department}","${app.category}","${app.pricing_model}","${app.url}","${app.app_owner}","${app.num_users}","${new Intl.NumberFormat().format(app.num_visits)}","$${currencyFormat(app.spend_amount)}","${app.spend_date}"`
  })
  if (budgets && budgets.length > 0) {
    csv.unshift(
      `Application,Department,Category,Pricing Model,Link,App Owner,Users,Visits,Total Transaction Amount (${dateFilter}),Last Transaction Date,Budget`
    )
  } else {
    csv.unshift(
      `Application,Department,Category,Pricing Model,Link,App Owner,Users,Visits,Total Transaction Amount (${dateFilter}),Last Transaction Date`
    )
  }
  const csvData = csv.join('\n')
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `diminish-overview-${new Date().toISOString()}.csv`
  a.click()
}

const getAppOwners = (apps: Application[] | undefined): string[] => {
  const owners: string[] = []
  if (!apps) return owners
  for (const app of apps) {
    if (app.app_owner && !owners.includes(app.app_owner)) {
      owners.push(app.app_owner)
    }
  }
  return owners.sort()
}
const getProcurementOwners = (apps: Application[] | undefined): string[] => {
  const owners: string[] = []
  if (!apps) return owners
  for (const app of apps) {
    if (app.procurement_owner && !owners.includes(app.procurement_owner)) {
      owners.push(app.procurement_owner)
    }
  }
  return owners.sort()
}

const getAppDepartments = (apps: Application[] | undefined): string[] => {
  const departments: string[] = []
  if (!apps) return departments
  for (const app of apps) {
    if (app.department && !departments.includes(app.department)) {
      departments.push(app.department)
    }
  }
  return departments.sort()
}

const FilterButton = styled(Button)`
  background: ${theme.color.white};
  color: ${theme.color.textDefault};
  padding: 6px 16px;
`

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  position: sticky;
  top: 77px;
  background: ${theme.color.backgroudDefault};
  z-index: 5;
`

enum TableSort {
  APPLICATION = 'application',
  DEPARTMENT = 'department',
  USERS = 'users',
  VISITS = 'visits',
  TRANSACTION_AMOUNT = 'transaction_amount',
  TRANSACTION_DATE = 'transaction_date',
}

export type Application = {
  id: number
  name: string
  logo: string
  category: string
  url: string
  department: string
  pricing_model: string
  app_owner: string
  procurement_owner: string
  num_users: number
  num_visits: number
  spend_amount: number
  spend_date: string
  spend_breakdown: SpendBreakdown[]
  children: Application[]
  approved: boolean
}

type SpendBreakdown = {
  spend_amount: number
  spend_date: string
}

export type Budget = {
  saas_tool_id: number | null
  department_id: number | null
  amount: number
  budget_start_date: string
  budget_end_date: string
}

export type Budgets = {
  app_budgets: Budget[] | null
  department_budgets: Budget[] | null
}

enum PayFilter {
  ALL = 'All',
  PAID = 'Paid',
  UNPAID = 'Unpaid',
}

enum SpendAmountType {
  GREATER = 'Greater than',
  LESS = 'Less than',
}

enum DateFilterType {
  BEFORE = 'Before',
  AFTER = 'After',
}

enum ApproveFilter {
  ALL = 'All',
  APPROVED = 'Approved',
  UNAPPROVED = 'Unapproved',
}

export type TableFilters = {
  appFilter: string
  departmentFilter?: string[]
  ownerFilter: string[]
  procurementOwnerFilter: string[]
  payFilter?: PayFilter
  approveFilter?: ApproveFilter
  spendAmountFilter?: { type?: SpendAmountType; amount?: number }
  contractValueFilter?: { type?: SpendAmountType; amount?: number }
  startDate?: { type?: DateFilterType; date?: string }
  endDate?: { type?: DateFilterType; date?: string }
}
export const filterSubstring = (str: string, filter: string) => {
  return filter.length === 0 || str.toLowerCase().includes(filter.toLowerCase())
}

export const filterStringIncludes = (str: string, filters: string[]) => {
  return filters.length === 0 || filters.includes(str)
}

const matchesPayFilter = (
  spendAmount: number,
  filter: PayFilter | undefined
) => {
  if (filter === PayFilter.PAID) {
    return spendAmount > 0
  }
  if (filter === PayFilter.UNPAID) {
    return spendAmount <= 0
  }
  return true
}

export const matchesSpendAmountFilter = (
  spendAmount: number,
  filter: { type?: SpendAmountType; amount?: number } | undefined
) => {
  if (!filter || !filter.type) {
    return true
  }
  if (filter.type === SpendAmountType.GREATER) {
    return spendAmount > (filter.amount ?? 0)
  }
  if (filter.type === SpendAmountType.LESS) {
    return spendAmount < (filter.amount ?? 0)
  }
  return true
}

const matchesApproveFilter = (
  approved: boolean,
  filter: ApproveFilter | undefined
) => {
  if (filter === ApproveFilter.APPROVED) {
    return approved
  }
  if (filter === ApproveFilter.UNAPPROVED) {
    return !approved
  }
  return true
}

export const dateFilter = (
  date: string,
  filter: { type?: DateFilterType; date?: string } | undefined
) => {
  if (!filter || !filter.type || !filter.date) {
    return true
  }
  if (filter.type === DateFilterType.BEFORE) {
    return date < (filter?.date ?? '')
  }
  if (filter.type === DateFilterType.AFTER) {
    return date > (filter?.date ?? '')
  }
  return true
}

const filterApps = (apps: Application[], filters: TableFilters) => {
  const displayApps: Application[] = []
  for (const app of apps || []) {
    if (
      !filterSubstring(app.name, filters.appFilter) ||
      !filterStringIncludes(app.department, filters.departmentFilter || []) ||
      !filterStringIncludes(app.app_owner, filters.ownerFilter) ||
      !filterStringIncludes(
        app.procurement_owner,
        filters.procurementOwnerFilter
      ) ||
      !matchesPayFilter(app.spend_amount, filters.payFilter) ||
      !matchesSpendAmountFilter(app.spend_amount, filters.spendAmountFilter) ||
      !matchesApproveFilter(app.approved, filters.approveFilter)
    ) {
      continue
    }
    displayApps.push(app)
  }
  return displayApps
}

export const ApplicationsTable = () => {
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const { budgetOverview } = useFlags()
  const dropdownOptions = GetDefaultDateOptions()

  const [apps, setApps] = useState<Application[] | undefined>(undefined)
  const [budgets, setBudgets] = useState<Budget[] | undefined>(undefined)
  const [users, setUsers] = useState<UserType[] | undefined>(undefined)
  const [departments, setDepartments] = useState<string[] | undefined>(
    undefined
  )
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    appFilter: '',
    payFilter: PayFilter.ALL,
    departmentFilter: [],
    ownerFilter: [],
    procurementOwnerFilter: [],
    approveFilter: ApproveFilter.ALL,
    spendAmountFilter: { type: undefined, amount: 0 },
  })
  const [dateFilter, setDateFilter] = useState<string>(dropdownOptions[0][0])
  const [sortAttr, setSortAttr] = useState<TableSort>(TableSort.APPLICATION)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC)
  const [page, setPage] = useState(0)

  const sort = (applications: Application[] | undefined) => {
    if (!applications) {
      return applications
    }
    const sorted = [...applications]
    switch (sortAttr) {
      case TableSort.APPLICATION:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.name.localeCompare(b.name))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.name.localeCompare(a.name))
        break
      case TableSort.DEPARTMENT:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.department.localeCompare(b.department))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.department.localeCompare(a.department))
        break
      case TableSort.USERS:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.num_users - b.num_users)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.num_users - a.num_users)
        break
      case TableSort.VISITS:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.num_visits - b.num_visits)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.num_visits - a.num_visits)
        break
      case TableSort.TRANSACTION_DATE:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.spend_date.localeCompare(b.spend_date))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.spend_date.localeCompare(a.spend_date))
        break
      case TableSort.TRANSACTION_AMOUNT:
      default:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.spend_amount - b.spend_amount)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.spend_amount - a.spend_amount)
    }
    return sorted
  }

  const sortedApps = useMemo((): Application[] | undefined => {
    return sort(apps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAttr, sortOrder])

  useEffect(() => {
    setApps(undefined)
    const getApps = async () => {
      const filter = dateFilter.toLowerCase().replaceAll(' ', '_')
      const reqPath = addImpersonationParams(
        `/usage/all?filter=${filter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const appUsage = await backendRequest(reqPath)
      if (appUsage.error) {
        setApps([])
      }
      const sorted = sort(appUsage.usage)
      setApps(sorted || [])
      setBudgets(appUsage.budgets?.app_budgets ?? [])
    }
    getApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        '/user_names',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch users', ToastType.ERROR)
        setUsers([])
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const getDepartments = async () => {
      const reqPath = addImpersonationParams(
        '/departments',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch departments', ToastType.ERROR)
        setDepartments([])
      }
      setDepartments(resp)
    }
    getDepartments()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    setApps(sortedApps)
  }, [sortedApps])

  const onSortChange = (selected: TableSort) => {
    if (!apps) return
    if (sortAttr === selected) {
      setSortOrder(sortOrder * -1)
    } else {
      setSortOrder(SortOrder.ASC)
      setSortAttr(selected)
    }
    setPage(0)
  }

  const onOwnerChange = (appId: number, userHash: string | null) => {
    if (!apps) return
    let userName = ''
    for (const user of users || []) {
      if (user.hash === userHash) {
        userName = getUserName(user)
        break
      }
    }
    for (const app of apps || []) {
      if (app.id === appId) {
        app.app_owner = userName
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.app_owner = userName
        }
      }
    }
    setApps([...apps])
  }

  const onDepartmentChange = (appId: number, department: string) => {
    if (!apps) return
    for (const app of apps || []) {
      if (app.id === appId) {
        app.department = department
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.department = department
        }
      }
    }
    setApps([...apps])
  }

  const onApprove = (appId: number, checked: boolean) => {
    if (!apps) return
    for (const app of apps) {
      if (app.id === appId) {
        app.approved = checked
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.approved = checked
        }
      }
    }
    setApps([...apps])
  }

  const displayApps: Application[] | undefined = useMemo(() => {
    if (!apps) {
      return undefined
    }
    return filterApps(apps, tableFilters)
  }, [apps, tableFilters])

  const paginatedApps = useMemo(() => {
    if (!displayApps) {
      return undefined
    }
    return displayApps.slice(page * pageSize, (page + 1) * pageSize)
  }, [displayApps, page])

  const pageCount = useMemo(() => {
    if (!displayApps) {
      return 0
    }
    return Math.ceil(displayApps.length / pageSize)
  }, [displayApps])

  return (
    <div style={{ overflow: 'visible' }}>
      <HeaderContainer>
        <HeadingMedium color="textDefault">
          Applications
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {displayApps && ` (${displayApps.length})`}
          </span>
        </HeadingMedium>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Dropdown
            options={dropdownOptions}
            defaultValue={dateFilter}
            onSelect={setDateFilter}
          />
          <GhostButton
            onClick={() => downloadOverview(displayApps, budgets, dateFilter)}
            style={{ padding: '12px' }}
          >
            <DownloadIcon />
          </GhostButton>
        </div>
      </HeaderContainer>
      <FilterSection
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        departments={getAppDepartments(apps)}
        owners={getAppOwners(apps)}
        procurementOwners={getProcurementOwners(apps)}
      />
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          maxHeight: 'calc(100vh - 272px)',
          zIndex: 4,
        }}
      >
        <TableWrapper cellSpacing={0}>
          <AppTableHeader
            sortAttr={sortAttr}
            sortOrder={sortOrder}
            onSortChange={onSortChange}
            budgetOverview={budgetOverview}
          />

          <AppTableBody
            apps={paginatedApps}
            hasFilter={displayApps?.length !== apps?.length}
            users={users}
            departments={departments}
            onApprove={onApprove}
            onOwnerChange={onOwnerChange}
            onDepartmentChange={onDepartmentChange}
            budgets={budgets}
            dateFilter={dateFilter}
          />
        </TableWrapper>
        <Pagination
          page={page}
          pageCount={pageCount}
          total={apps?.length || 0}
          setPage={setPage}
        />
      </div>
    </div>
  )
}

export const FilterSection = ({
  tableFilters,
  setTableFilters,
  departments,
  owners,
  procurementOwners,
}: {
  tableFilters: TableFilters
  setTableFilters: React.Dispatch<React.SetStateAction<TableFilters>>
  departments: string[] | undefined
  owners: string[]
  procurementOwners: string[]
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [showFilters, setShowFilters] = useState(false)
  const [filterSelected, setFilterSelected] = useState<
    keyof TableFilters | undefined
  >(undefined)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node)) {
        setShowFilters(false)
        setFilterSelected(undefined)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showFilters, ref])

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        gap: '10px',
        backgroundColor: theme.color.white,
        border: `1px solid ${theme.color.border}`,
        borderBottom: 'none',
        padding: '10px 14px',
        position: 'sticky',
        top: '159px',
        zIndex: 4,
      }}
    >
      {(filterSelected === 'appFilter' ||
        tableFilters.appFilter.length > 0) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('appFilter')}>
            Applications: {tableFilters.appFilter}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({ ...prev, appFilter: '' }))
              }
            />
          </FilterButton>
          {filterSelected === 'appFilter' && (
            <OptionsWrapper
              style={{ width: '150px', top: '48px', padding: '12px' }}
            >
              <input
                type="text"
                style={{ height: '24px' }}
                placeholder="Search"
                autoFocus={true}
                onChange={(e) =>
                  setTableFilters((prev) => ({
                    ...prev,
                    appFilter: e.target.value,
                  }))
                }
                value={tableFilters.appFilter}
              />
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'departmentFilter' ||
        (tableFilters.departmentFilter &&
          tableFilters.departmentFilter.length > 0)) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('departmentFilter')}>
            Departments ({tableFilters.departmentFilter?.length || 0})
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({ ...prev, departmentFilter: [] }))
              }
            />
          </FilterButton>
          {filterSelected === 'departmentFilter' && (
            <OptionsWrapper
              style={{ maxHeight: '350px', width: '150px', top: '48px' }}
            >
              {departments?.map((department, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      departmentFilter: prev.departmentFilter?.includes(
                        department
                      )
                        ? prev.departmentFilter.filter((d) => d !== department)
                        : [...(prev.departmentFilter || []), department],
                    }))
                  }
                >
                  <input
                    type="checkbox"
                    readOnly
                    checked={
                      tableFilters.departmentFilter?.includes(department) ??
                      false
                    }
                  />
                  {department}
                </Option>
              ))}
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'ownerFilter' ||
        tableFilters.ownerFilter.length > 0) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('ownerFilter')}>
            Owners ({tableFilters.ownerFilter.length})
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({ ...prev, ownerFilter: [] }))
              }
            />
          </FilterButton>
          {filterSelected === 'ownerFilter' && (
            <OptionsWrapper style={{ width: '180px', top: '48px' }}>
              {owners?.map((owner, i) => (
                <Option
                  key={i}
                  style={{
                    padding: '12px',
                    display: 'flex',
                    gap: '8px',
                  }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      ownerFilter: prev.ownerFilter.includes(owner)
                        ? prev.ownerFilter.filter((d) => d !== owner)
                        : [...prev.ownerFilter, owner],
                    }))
                  }
                >
                  <input
                    type="checkbox"
                    readOnly
                    checked={tableFilters.ownerFilter.includes(owner)}
                  />
                  {owner}
                </Option>
              ))}
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'procurementOwnerFilter' ||
        tableFilters.procurementOwnerFilter.length > 0) && (
        <div style={{ position: 'relative' }}>
          <FilterButton
            onClick={() => setFilterSelected('procurementOwnerFilter')}
          >
            Procurement Owners ({tableFilters.procurementOwnerFilter.length})
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  procurementOwnerFilter: [],
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'procurementOwnerFilter' && (
            <OptionsWrapper style={{ width: '180px', top: '48px' }}>
              {procurementOwners.map((owner, i) => (
                <Option
                  key={i}
                  style={{
                    padding: '12px',
                    display: 'flex',
                    gap: '8px',
                  }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      procurementOwnerFilter:
                        prev.procurementOwnerFilter.includes(owner)
                          ? prev.procurementOwnerFilter.filter(
                              (d) => d !== owner
                            )
                          : [...prev.procurementOwnerFilter, owner],
                    }))
                  }
                >
                  <input
                    type="checkbox"
                    readOnly
                    checked={tableFilters.procurementOwnerFilter.includes(
                      owner
                    )}
                  />
                  {owner}
                </Option>
              ))}
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'payFilter' ||
        (tableFilters.payFilter !== PayFilter.ALL &&
          tableFilters.payFilter !== undefined)) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('payFilter')}>
            Transactions: {tableFilters.payFilter}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  payFilter: PayFilter.ALL,
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'payFilter' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(PayFilter).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      payFilter: filter,
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.payFilter === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'spendAmountFilter' ||
        tableFilters.spendAmountFilter?.type !== undefined) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('spendAmountFilter')}>
            Spend Amount: {tableFilters.spendAmountFilter?.type} $
            {currencyFormat(tableFilters.spendAmountFilter?.amount || 0)}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  spendAmountFilter: { type: undefined, amount: 0 },
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'spendAmountFilter' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(SpendAmountType).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      spendAmountFilter: {
                        ...prev.spendAmountFilter,
                        type: filter,
                      },
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.spendAmountFilter?.type === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
              <Option>
                <input
                  type="number"
                  style={{ height: '24px', width: '100%' }}
                  placeholder="Amount"
                  onChange={(e) =>
                    setTableFilters((prev) => ({
                      ...prev,
                      spendAmountFilter: {
                        ...prev.spendAmountFilter,
                        amount: parseFloat(e.target.value),
                      },
                    }))
                  }
                  value={tableFilters.spendAmountFilter?.amount ?? 0}
                />
              </Option>
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'contractValueFilter' ||
        tableFilters.contractValueFilter?.type !== undefined) && (
        <div style={{ position: 'relative' }}>
          <FilterButton
            onClick={() => setFilterSelected('contractValueFilter')}
          >
            Contract Value: {tableFilters.contractValueFilter?.type} $
            {currencyFormat(tableFilters.contractValueFilter?.amount || 0)}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  contractValueFilter: { type: undefined, amount: 0 },
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'contractValueFilter' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(SpendAmountType).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      contractValueFilter: {
                        ...prev.contractValueFilter,
                        type: filter,
                      },
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.contractValueFilter?.type === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
              <Option>
                <input
                  type="number"
                  style={{ height: '24px', width: '100%' }}
                  placeholder="Amount"
                  onChange={(e) =>
                    setTableFilters((prev) => ({
                      ...prev,
                      contractValueFilter: {
                        ...prev.contractValueFilter,
                        amount: parseFloat(e.target.value),
                      },
                    }))
                  }
                  value={tableFilters.contractValueFilter?.amount ?? 0}
                />
              </Option>
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'approveFilter' ||
        (tableFilters.approveFilter !== ApproveFilter.ALL &&
          tableFilters.approveFilter !== undefined)) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('approveFilter')}>
            Approve Status: {tableFilters.approveFilter}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  approveFilter: ApproveFilter.ALL,
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'approveFilter' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(ApproveFilter).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      approveFilter: filter,
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.approveFilter === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'startDate' || tableFilters.startDate?.date) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('startDate')}>
            Start Date: {tableFilters.startDate?.type}{' '}
            {tableFilters.startDate?.date || ''}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  startDate: { type: undefined, date: '' },
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'startDate' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(DateFilterType).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      startDate: {
                        ...prev.startDate,
                        type: filter,
                      },
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.startDate?.type === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
              <Option>
                <input
                  type="text"
                  style={{ height: '24px', width: '100%' }}
                  placeholder="Date"
                  onChange={(e) =>
                    setTableFilters((prev) => ({
                      ...prev,
                      startDate: {
                        ...prev.startDate,
                        date: e.target.value,
                      },
                    }))
                  }
                  value={tableFilters.startDate?.date ?? ''}
                />
              </Option>
            </OptionsWrapper>
          )}
        </div>
      )}
      {(filterSelected === 'endDate' || tableFilters.endDate?.date) && (
        <div style={{ position: 'relative' }}>
          <FilterButton onClick={() => setFilterSelected('endDate')}>
            End Date: {tableFilters.endDate?.type}{' '}
            {tableFilters.endDate?.date || ''}
            <CloseX
              onClick={() =>
                setTableFilters((prev) => ({
                  ...prev,
                  endDate: { type: undefined, date: '' },
                }))
              }
            />
          </FilterButton>
          {filterSelected === 'endDate' && (
            <OptionsWrapper style={{ width: '150px', top: '48px' }}>
              {Object.values(DateFilterType).map((filter, i) => (
                <Option
                  key={i}
                  style={{ padding: '12px', display: 'flex', gap: '8px' }}
                  onClick={() =>
                    setTableFilters((prev) => ({
                      ...prev,
                      endDate: {
                        ...prev.endDate,
                        type: filter,
                      },
                    }))
                  }
                >
                  <input
                    type="radio"
                    readOnly
                    checked={tableFilters.endDate?.type === filter}
                    style={{ accentColor: theme.color.textPurple }}
                  />
                  {filter}
                </Option>
              ))}
              <Option>
                <input
                  type="text"
                  style={{ height: '24px', width: '100%' }}
                  placeholder="Date"
                  onChange={(e) =>
                    setTableFilters((prev) => ({
                      ...prev,
                      endDate: {
                        ...prev.endDate,
                        date: e.target.value,
                      },
                    }))
                  }
                  value={tableFilters.endDate?.date ?? ''}
                />
              </Option>
            </OptionsWrapper>
          )}
        </div>
      )}
      <AddFilterButton
        showFilters={showFilters}
        setFilterSelected={setFilterSelected}
        tableFilters={tableFilters}
        setShowFilters={setShowFilters}
      />
    </div>
  )
}

const AddFilterButton = ({
  showFilters,
  setFilterSelected,
  tableFilters,
  setShowFilters,
}: {
  showFilters: boolean
  setFilterSelected: React.Dispatch<
    React.SetStateAction<keyof TableFilters | undefined>
  >
  tableFilters: TableFilters
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const selectFilter = (filterType: keyof TableFilters) => {
    setFilterSelected(filterType)
    setShowFilters(false)
  }

  return (
    Object.values(tableFilters).some(
      (value) => value !== '' && !(Array.isArray(value) && value.length === 0)
    ) && (
      <>
        <FilterButton
          onClick={() => {
            setShowFilters(!showFilters)
            setFilterSelected(undefined)
          }}
        >
          + Add Filter
        </FilterButton>
        <div
          style={{
            position: 'relative',
            top: '48px',
            left: '-112px',
            zIndex: 50,
          }}
        >
          <OptionsWrapper
            style={{
              maxHeight: '400px',
              width: '160px',
              color: theme.color.textDefault,
              visibility: showFilters ? 'visible' : 'hidden',
              zIndex: 50,
            }}
          >
            {tableFilters.appFilter.length === 0 && (
              <Option onClick={() => selectFilter('appFilter')}>
                Application
              </Option>
            )}
            {tableFilters.departmentFilter &&
              tableFilters.departmentFilter.length === 0 && (
                <Option onClick={() => selectFilter('departmentFilter')}>
                  Department
                </Option>
              )}
            {tableFilters.ownerFilter.length === 0 && (
              <Option onClick={() => selectFilter('ownerFilter')}>Owner</Option>
            )}
            {tableFilters.procurementOwnerFilter.length === 0 && (
              <Option onClick={() => selectFilter('procurementOwnerFilter')}>
                Procurement Owner
              </Option>
            )}
            {tableFilters.payFilter === PayFilter.ALL && (
              <Option onClick={() => selectFilter('payFilter')}>
                Transactions
              </Option>
            )}
            {tableFilters.approveFilter === ApproveFilter.ALL && (
              <Option onClick={() => selectFilter('approveFilter')}>
                Approve Status
              </Option>
            )}
            {tableFilters.spendAmountFilter &&
              tableFilters.spendAmountFilter.type === undefined && (
                <Option onClick={() => selectFilter('spendAmountFilter')}>
                  Spend Amount
                </Option>
              )}
            {tableFilters.contractValueFilter &&
              tableFilters.contractValueFilter.type === undefined && (
                <Option onClick={() => selectFilter('contractValueFilter')}>
                  Contract Value
                </Option>
              )}
            {tableFilters.startDate &&
              tableFilters.startDate.type === undefined && (
                <Option onClick={() => selectFilter('startDate')}>
                  Start Date
                </Option>
              )}
            {tableFilters.endDate &&
              tableFilters.endDate.type === undefined && (
                <Option onClick={() => selectFilter('endDate')}>
                  End Date
                </Option>
              )}
          </OptionsWrapper>
        </div>
      </>
    )
  )
}

const AppTableHeader = ({
  sortAttr,
  sortOrder,
  onSortChange,
  budgetOverview,
}: {
  sortAttr: TableSort
  sortOrder: SortOrder
  onSortChange: (selected: TableSort) => void
  budgetOverview: boolean
}) => {
  return (
    <TableHead style={{ top: '0px', position: 'sticky', zIndex: 3 }}>
      <TableRow
        style={{
          background: theme.color.backgroudDefault,
          position: 'sticky',
          top: '0px',
        }}
      >
        <TableCell
          w="15px"
          style={{
            background: theme.color.backgroudDefault,
            position: 'sticky',
            left: '0px',
            zIndex: 4,
          }}
        ></TableCell>
        <SortableHeader
          isSorted={sortAttr === TableSort.APPLICATION}
          sortOrder={sortOrder}
          onClick={() => onSortChange(TableSort.APPLICATION)}
          mw="160px"
          style={{
            boxShadow: `3px 0 6px -3px ${theme.color.border}`,
            position: 'sticky',
            background: theme.color.backgroudDefault,
            zIndex: 4,
            left: '45px',
          }}
        >
          Application
        </SortableHeader>
        <SortableHeader
          isSorted={sortAttr === TableSort.DEPARTMENT}
          sortOrder={sortOrder}
          onClick={() => onSortChange(TableSort.DEPARTMENT)}
          w="250px"
        >
          Department
        </SortableHeader>
        <TableCell w="200px">App Owner</TableCell>
        <TableCell w="200px">Procurement Owner</TableCell>
        {!budgetOverview ? (
          <>
            <SortableHeader
              isSorted={sortAttr === TableSort.USERS}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.USERS)}
              mw="90px"
            >
              Users
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.VISITS}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.VISITS)}
              mw="90px"
            >
              Visits
            </SortableHeader>
          </>
        ) : (
          <></>
        )}
        <SortableHeader
          isSorted={sortAttr === TableSort.TRANSACTION_AMOUNT}
          sortOrder={sortOrder}
          onClick={() => onSortChange(TableSort.TRANSACTION_AMOUNT)}
          mw="120px"
        >
          Transaction Amount
        </SortableHeader>
        <SortableHeader
          isSorted={sortAttr === TableSort.TRANSACTION_DATE}
          sortOrder={sortOrder}
          onClick={() => onSortChange(TableSort.TRANSACTION_DATE)}
          mw="120px"
        >
          Transaction Date
        </SortableHeader>
        {budgetOverview ? (
          <>
            <TableCell mw="250px">Budget Amount</TableCell>
            <TableCell mw="250px">BvA</TableCell>
          </>
        ) : (
          <></>
        )}
        <TableCell
          style={{
            position: 'sticky',
            right: '0',
            backgroundColor: theme.color.backgroudDefault,
            boxShadow: `-3px 0 6px -3px ${theme.color.border}`,
          }}
          w="80px"
        >
          Approved
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const pageSize = 10

const AppTableBody = ({
  apps,
  users,
  departments,
  onApprove,
  onOwnerChange,
  onDepartmentChange,
  budgets,
  dateFilter,
  hasFilter,
}: {
  apps: Application[] | undefined
  users: UserType[] | undefined
  departments: string[] | undefined
  onApprove: (appId: number, checked: boolean) => void
  onOwnerChange: (appId: number, userHash: string | null) => void
  onDepartmentChange: (appId: number, department: string) => void
  budgets: Budget[] | undefined
  dateFilter: string
  hasFilter: boolean
}) => {
  if (apps === undefined) {
    return (
      <TableBody>
        <TableRow style={{ width: '100%', height: '50px' }}>
          <TableCell colSpan={15}>
            <Loading />
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  if (hasFilter && apps.length === 0) {
    return (
      <TableBody>
        <TooManyFiltersTableBody colSpan={15} />
      </TableBody>
    )
  }

  if (apps.length === 0) {
    return (
      <TableBody>
        <EmptyAppsTableBody colSpan={15} />
      </TableBody>
    )
  }

  return (
    <TableBody>
      {apps.map((app, i) => (
        <AppTableRow
          key={i}
          app={app}
          users={users}
          departments={departments}
          onApprove={onApprove}
          onOwnerChange={onOwnerChange}
          onDepartmentChange={onDepartmentChange}
          budget={budgets?.find((b) => b.saas_tool_id === app.id)}
          dateFilter={dateFilter}
        />
      ))}
    </TableBody>
  )
}

export const Pagination = ({
  page,
  pageCount,
  total,
  setPage,
}: {
  page: number
  pageCount: number
  total: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  return (
    <div
      style={{
        position: 'sticky',
        bottom: '0px',
        left: '0px',
        background: theme.color.white,
        zIndex: 3,
        width: 'calc(100vw - 126px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 12px',
        border: `1px solid ${theme.color.border}`,
      }}
    >
      <div>
        {page * pageSize + 1} -{' '}
        {Math.min((page + 1) * pageSize, pageCount * pageSize)} of {total} items
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <HeadingSmallest>
          Page {page + 1} of {pageCount}
        </HeadingSmallest>
        <PaginationButton
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={page === 0}
        >
          <KeyboardArrowLeft />
          <p color="inherit">Prev</p>
        </PaginationButton>
        <PaginationButton
          onClick={() => setPage((prev) => Math.min(prev + 1, pageCount - 1))}
          disabled={page === pageCount - 1}
        >
          <p>Next</p>
          <KeyboardArrowRight />
        </PaginationButton>
      </div>
    </div>
  )
}

export const PaginationButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '8px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
  },
})

const AppTableRow = ({
  app,
  users,
  departments,
  onApprove,
  onOwnerChange,
  onDepartmentChange,
  budget,
  dateFilter,
}: {
  app: Application
  users: UserType[] | undefined
  departments: string[] | undefined
  onApprove: (appId: number, checked: boolean) => void
  onOwnerChange: (appId: number, userHash: string | null) => void
  onDepartmentChange: (appId: number, department: string) => void
  budget: Budget | undefined
  dateFilter: string
}) => {
  const [showChildren, setShowChildren] = useState(false)

  const { budgetOverview } = useFlags()
  const monthlyBudget = getRangeBudget(dateFilter, budget)

  return (
    <>
      <TableRow style={{ boxShadow: '' }}>
        <ArrowDownCell
          onClick={() => setShowChildren(!showChildren)}
          open={showChildren}
        />
        <ApplicationCell id={app.id} logo={app.logo} name={app.name} />
        <DepartmentCell
          appId={app.id}
          options={departments || []}
          defaulOption={app.department}
          onChange={onDepartmentChange}
        />
        <OwnerCell
          appId={app.id}
          options={users || []}
          defaulOption={app.app_owner}
          onChange={onOwnerChange}
          ownerType="app"
        />
        <OwnerCell
          appId={app.id}
          options={users || []}
          defaulOption={app.procurement_owner}
          ownerType="procurement"
        />
        {!budgetOverview ? (
          <>
            <TableCell>{app.num_users > 0 ? app.num_users : '-'}</TableCell>
            <TableCell>{app.num_visits > 0 ? app.num_visits : '-'}</TableCell>
          </>
        ) : (
          <></>
        )}
        <TableCell>${currencyFormat(app.spend_amount)}</TableCell>
        <TableCell mw="50px">
          {app.spend_date ? app.spend_date : <p>-</p>}
        </TableCell>
        {budgetOverview ? (
          <>
            <TableCell mw="250px">${currencyFormat(monthlyBudget)}</TableCell>
            <BudgetDiff budgetDiff={monthlyBudget - app.spend_amount} />
          </>
        ) : (
          <></>
        )}
        <SliderCell appId={app.id} state={app.approved} onClick={onApprove} />
      </TableRow>
      {showChildren && (
        <SubRows
          app={app}
          spendBreakdown={app.spend_breakdown}
          apps={app.children}
          users={users}
          onOwnerChange={onOwnerChange}
          budget={budget}
          dateFilter={dateFilter}
        />
      )}
    </>
  )
}

const SubRows = ({
  spendBreakdown,
  app,
  apps,
  users,
  onOwnerChange,
  budget,
  dateFilter,
}: {
  spendBreakdown: SpendBreakdown[]
  app: Application
  apps: Application[]
  users: UserType[] | undefined
  onOwnerChange: (appId: number, userHash: string | null) => void
  budget: Budget | undefined
  dateFilter: string
}) => {
  return (
    <SubTable>
      <SubTableCell colSpan={15}>
        <AppMoreInfo app={app} budget={budget} dateFilter={dateFilter} />
        {apps.length > 0 && (
          <ChildRows apps={apps} users={users} onOwnerChange={onOwnerChange} />
        )}
        <ComplianceCerts app={app} />
        {spendBreakdown.length > 1 && (
          <SpendBreakdownRows spendBreakdown={spendBreakdown} />
        )}
      </SubTableCell>
    </SubTable>
  )
}

export const AppMoreInfo = ({
  app,
  budget,
  dateFilter,
}: {
  app: Application
  budget: Budget | undefined
  dateFilter: string
}) => {
  const { budgetOverview } = useFlags()

  const monthlyBudget = getRangeBudget(dateFilter, budget)
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <SubTableRow>
        <TitleCell>More Info</TitleCell>
        <TableCell>
          <table cellSpacing={0} style={{ width: '100%' }}>
            <SubTableHead>
              <SubTableRow>
                <SubTableHeading>Category</SubTableHeading>
                <SubTableHeading>Source</SubTableHeading>
                <SubTableHeading>Pricing Model</SubTableHeading>
                {budget && !budgetOverview ? (
                  <>
                    <SubTableHeading>Budget</SubTableHeading>
                    <SubTableHeading>Budget Difference</SubTableHeading>
                  </>
                ) : (
                  <></>
                )}
                <SubTableHeading style={{ width: '35px' }}></SubTableHeading>
              </SubTableRow>
            </SubTableHead>
            <SubTableRow>
              <TableCell>{app.category ? app.category : '-'}</TableCell>
              <SubTableCell>
                <a
                  href={`https://${app.url}`}
                  target="_blank"
                  style={{
                    color: theme.color.textSubdued,
                    fontWeight: 500,
                    textDecoration: 'none',
                  }}
                  rel="noreferrer"
                >
                  Link
                </a>
              </SubTableCell>
              <TableCell>
                {app.pricing_model ? app.pricing_model : '-'}
              </TableCell>
              {budget && !budgetOverview ? (
                <>
                  <SubTableCell>${currencyFormat(monthlyBudget)}</SubTableCell>
                  <BudgetDiff budgetDiff={monthlyBudget - app.spend_amount} />
                </>
              ) : (
                <></>
              )}
              <SubTableCell style={{ width: '35px' }}></SubTableCell>
            </SubTableRow>
          </table>
        </TableCell>
      </SubTableRow>
    </table>
  )
}

const BudgetDiff = ({ budgetDiff }: { budgetDiff: number }) => {
  const cellStyle = {
    color: budgetDiff >= 0 ? theme.color.ChipGreen : theme.color.ChipRed,
    display: 'flex',
    alignItems: 'center',
  }
  return (
    <TableCell mw="250px">
      <div style={cellStyle}>
        {budgetDiff >= 0 ? <PlusCircle /> : <MinusCircle />}$
        {currencyFormat(Math.abs(budgetDiff))}
      </div>
    </TableCell>
  )
}

const ChildRows = ({
  apps,
  users,
  onOwnerChange,
}: {
  apps: Application[]
  users: UserType[] | undefined
  onOwnerChange: (appId: number, userHash: string | null) => void
}) => {
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <SubTableRow>
        <TitleCell rowSpan={apps.length + 1}>
          Products{' '}
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            ({apps.length})
          </span>
        </TitleCell>
        <TableCell>
          <table cellSpacing={0} style={{ width: '100%' }}>
            <SubTableHead>
              <SubTableRow>
                <SubTableHeading>Application</SubTableHeading>
                <SubTableHeading style={{ width: '250px' }}>
                  App Owner
                </SubTableHeading>
                <SubTableHeading style={{ width: '250px' }}>
                  Procurement Owner
                </SubTableHeading>
                <SubTableHeading style={{ maxWidth: '50px' }}>
                  Users
                </SubTableHeading>
                <SubTableHeading style={{ maxWidth: '50px' }}>
                  Visits
                </SubTableHeading>
                <SubTableHeading style={{ width: '35px' }}></SubTableHeading>
              </SubTableRow>
            </SubTableHead>
            {apps.map((app, i) => (
              <SubTableRow key={i}>
                <SubTableCell>
                  <a
                    href={`/product/${app.id}`}
                    style={{
                      color: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                    }}
                  >
                    {app.name}
                  </a>
                </SubTableCell>
                <OwnerCell
                  appId={app.id}
                  options={users || []}
                  defaulOption={app.app_owner}
                  onChange={onOwnerChange}
                  ownerType="app"
                />
                <OwnerCell
                  appId={app.id}
                  options={users || []}
                  defaulOption={app.procurement_owner}
                  onChange={onOwnerChange}
                  ownerType="procurement"
                />
                <TableCell mw="50px">
                  {app.num_users > 0 ? app.num_users : '-'}
                </TableCell>
                <TableCell>
                  {app.num_visits > 0 ? app.num_visits : '-'}
                </TableCell>
                <SubTableCell style={{ width: '35px' }}></SubTableCell>
              </SubTableRow>
            ))}
          </table>
        </TableCell>
      </SubTableRow>
    </table>
  )
}

const SpendBreakdownRows = ({
  spendBreakdown,
}: {
  spendBreakdown: SpendBreakdown[]
}) => {
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <SubTableRow>
        <TitleCell rowSpan={spendBreakdown.length + 1}>
          Spend Breakdown{' '}
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            ({spendBreakdown.length})
          </span>
        </TitleCell>
        <TableCell>
          <table cellSpacing={0} style={{ width: '100%' }}>
            <SubTableHead>
              <SubTableRow>
                <SubTableHeading>Amount</SubTableHeading>
                <SubTableHeading>Date</SubTableHeading>
                <SubTableHeading style={{ width: '5px' }}></SubTableHeading>
              </SubTableRow>
            </SubTableHead>
            {spendBreakdown.map((spend: SpendBreakdown, i: number) => (
              <SubTableRow key={i}>
                <SubTableCell>
                  ${currencyFormat(spend.spend_amount)}
                </SubTableCell>
                <SubTableCell>{spend.spend_date}</SubTableCell>
                <SubTableCell style={{ width: '5px' }}></SubTableCell>
              </SubTableRow>
            ))}
          </table>
        </TableCell>
      </SubTableRow>
    </table>
  )
}

const EmptyTableWrapper = styled('div')({
  borderTop: `1px solid ${theme.color.border}`,
  borderBottom: `1px solid ${theme.color.border}`,
  margin: '15px 0',
  padding: '15px 0',
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const EmptyAppsTableBody = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <EmptyTableWrapper>
        <OverviewTableIcon />
        <HeadingMedium color="textDefault">
          This is where you see your usage and spend data
        </HeadingMedium>
        <HeadingSmallest>
          Add your integrations to view the data.
        </HeadingSmallest>
        <Button onClick={() => (window.location.href = '/integrations')}>
          Set Up Your Integrations
        </Button>
      </EmptyTableWrapper>
    </TableCell>
  </TableRow>
)

export const TooManyFiltersTableBody = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <EmptyTableWrapper>
        <TooManyFiltersTableIcon />
        <HeadingMedium color="textDefault">
          No data available for the applied filters.
        </HeadingMedium>
        <HeadingSmallest>
          Please alter or remove some filters to refresh the results.
        </HeadingSmallest>
      </EmptyTableWrapper>
    </TableCell>
  </TableRow>
)
