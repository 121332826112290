import React, { ReactNode, useState } from 'react'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { addImpersonationParams, backendRequest } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'
import Loading from '../assets/Loading'
import { TextField } from '../components/InputFields'
import { CodeInline } from '../components/CodeBlock'
import { showToast, ToastType } from '../utils/toastify'

export const OktaModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  switch (modal) {
    case IntgModal.OktaCreate:
      return <OktaCreateModal setModal={setModal} onDismiss={onDismiss} />
    case IntgModal.OktaRemove:
      return (
        <OktaRemoveModal
          onRemoveSuccess={() => setModal(IntgModal.OktaRemovalSuccess)}
          onDismiss={onDismiss}
        />
      )
    case IntgModal.OktaRemovalSuccess:
      return <OktaRemoveSuccessModal onDismiss={onDismiss} />
    case IntgModal.OktaSuccess:
      return <OktaSuccessModal onDismiss={onDismiss} />
    case IntgModal.OktaFailure:
      return <OktaFailureModal onDismiss={onDismiss} />
    default:
      return <></>
  }
}

const OktaModalWrapper = ({
  onDismiss,
  onClick,
  heading,
  body,
  buttonTxt,
  disableBtn,
  loading,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  heading: string
  body: ReactNode
  buttonTxt: string
  disableBtn?: boolean
  loading: boolean
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '500px', borderColor: theme.color.textPurple }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      {body}
      <Divider />
      <ButtonWrapper>
        <GhostButton
          onClick={onDismiss}
          style={{ padding: '14px 24px', borderColor: theme.color.textPurple }}
        >
          Cancel
        </GhostButton>
        {loading ? (
          <Button onClick={onClick} disabled={true}>
            <Loading />
          </Button>
        ) : (
          <Button onClick={onClick} disabled={disableBtn}>
            {buttonTxt}
          </Button>
        )}
      </ButtonWrapper>
    </div>
  </Modal>
)

const OktaCreateModal = ({
  onDismiss,
  setModal,
}: {
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const reqPath = addImpersonationParams(
        '/integrations/okta_logs',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
        }),
      })
      if (resp.error) {
        setModal(IntgModal.OktaFailure)
        return
      }
      setModal(IntgModal.OktaSuccess)
    }
    createIntegration()
  }

  return (
    <OktaModalWrapper
      onDismiss={onDismiss}
      onClick={onClick}
      heading="Extract Login History from Okta"
      body={
        <>
          <HeadingSmallest color="textDefault">
            Connect your Okta account to extract login history data. Diminish
            will pull the signin activity of all users in your Okta account. The
            permissions of the API key will be inherited from the user creating
            the integration. Follow these steps to create a new API Key:
          </HeadingSmallest>
          <HeadingSmallest>
            <ol
              style={{
                paddingLeft: '30px',
                margin: '0',
              }}
            >
              <li>Log in to your Okta Admin Console</li>
              <li>
                Under Security on the sidebar, select{' '}
                <CodeInline>API</CodeInline>.
              </li>
              <li>On the API page, select the Tokens tab.</li>
              <li>Click the Create Token button.</li>
              <li>
                Give the token a name and select <CodeInline>any IP</CodeInline>{' '}
                on the dropdown, then click Create token.
              </li>
              <li>Copy the token and paste below.</li>
            </ol>
          </HeadingSmallest>
          <Divider />
          <TextField
            label={'Okta API Token'}
            onChange={(newToken) => setToken(newToken)}
          />
        </>
      }
      buttonTxt="Create"
      disableBtn={token.length === 0}
      loading={loading}
    />
  )
}

const OktaRemoveModal = ({
  onRemoveSuccess,
  onDismiss,
}: {
  onRemoveSuccess: () => void
  onDismiss: () => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const googleWorkspacePath = addImpersonationParams(
        '/integrations/okta_logs',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(googleWorkspacePath, {
        method: 'DELETE',
      })
      if (resp.error) {
        showToast('Failed to remove Okta integration', ToastType.ERROR)

        return
      }
      onRemoveSuccess()
    }
    createIntegration()
  }

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Are you sure you want to remove the Okta integration?
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          If you remove this integration, Diminish will no longer pull signin
          activity of all users in your Okta account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <GhostButton
            onClick={onDismiss}
            style={{
              padding: '14px 24px',
              borderColor: theme.color.errorRed,
            }}
          >
            Cancel
          </GhostButton>
          <Button onClick={onClick} disabled={loading} color="errorRed">
            {loading ? <Loading /> : 'Remove'}
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const OktaRemoveSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Okta Integration Removed Successfully
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully removed your Okta integration. Diminish can no
          longer pull signin activity from your Okta account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
const OktaSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Okta Integration Successful
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully connected your Okta account. Diminish will start
          pulling signin activity of all users in your Okta account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const OktaFailureModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Something Went Wrong
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          There was an issue creating the integration. Please contact support.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.errorRed,
              borderColor: theme.color.errorRed,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
