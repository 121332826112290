import { useState } from 'react'
import { HeadingSmallest } from '../../components/Text'
import Divider from '../../components/Divider'
import { TextField } from '../../components/InputFields'
import Button from '../../components/Button'
import {
  LoginCard,
  PageWrapper,
  PurpleLink,
  WelcomeText,
} from '../../components/LoginComponents'
import { backendRequest } from '../../utils/utils'

export default function ForgetPassword() {
  const [email, setEmail] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    await backendRequest('/forget_password', {
      method: 'POST',
      body: JSON.stringify({ email }),
    })
    setVisible(true)
  }

  const shouldDisableSubmit = () => {
    return !email
  }

  return (
    <PageWrapper>
      <LoginCard>
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <WelcomeText>Welcome to Diminish</WelcomeText>
          <Divider style={{ margin: '16px 0' }} />
          <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
            Please enter your Diminish account email
          </HeadingSmallest>
          <TextField
            label="Email"
            type="text"
            name="email"
            placeholder="Enter your email"
            onChange={(value) => setEmail(value)}
            marginBottom="32px"
          />

          <Button disabled={shouldDisableSubmit()} type="submit">
            Reset Password
          </Button>
          {visible && (
            <PurpleLink>
              We have sent you an email, if you have an account with Diminish.
            </PurpleLink>
          )}
        </form>
      </LoginCard>
    </PageWrapper>
  )
}
