import { CSSProperties, FormEvent, createRef, useEffect, useState } from 'react'
import { HeadingLarge } from '../../components/Text'
import styled from 'styled-components'
import theme from '../../Theme'
import { Profile } from '../../components/Profile'
import { TextField } from '../../components/InputFields'
import Button, { GhostButton } from '../../components/Button'
import DiminishLogo from '../../assets/logos/Diminish72.png'
import useAuth from '../../hooks/useAuth'
import { backendRequest, addImpersonationParams } from '../../utils/utils'
import Loading from '../../assets/Loading'
import Markdown from 'markdown-to-jsx'
import Copy from '../../assets/Copy'
import Checkmark from '../../assets/Checkmark'
import DownloadIcon from '../../assets/Download'
import { Tooltip } from 'react-tooltip'
import useImpersonation from '../../hooks/useImpersonation'
import Paper from '../../assets/Paper'
import { PDFPreviewModal } from '../../Overview/LatestInvoicesTable'
import OpenNewTabLight from '../../assets/OpenNewTabLight'
import BarChart from '../../assets/BarChart'
import { Chart } from 'react-chartjs-2'
import { GraphData } from '../../Overview/MainGraph'

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1200px;
  margin: auto;
  height: calc(100vh - 140px);
`
const HistoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  flex-grow: 10;
  max-height: calc(100vh - 305px);
  overflow-y: auto;

  table {
    width: 100%;
    border-spacing: 0;
  }
  thead {
    color: ${theme.color.textSubdued};
    background-color: ${theme.color.backgroundSubdued};
  }
  tbody {
    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
  tr:last-child td {
    border-bottom: 1px solid ${theme.color.border};
  }
  tr {
    td:last-child,
    th:last-child {
      border-right: 1px solid ${theme.color.border};
    }
  }
  td,
  th {
    text-align: center;
    padding: 8px 10px;
    border-left: 1px solid ${theme.color.border};
    border-top: 1px solid ${theme.color.border};
  }
`
const SampleQuestionsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
  flex-grow: 10;
  max-height: calc(100vh - 305px);
`
const SampleQuestionsCard = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid ${theme.color.border};
  border-radius: 4px;
  padding: 10px;
  width: 253px;
  height: 100px;
  cursor: pointer;
`

const PromptWrapper = styled('form')`
  display: flex;
`

type ConvoHistory = {
  name: string
  response: string
  contracts?: ContractPreviewProps[]
  app_name?: string
  app_id?: number
}

export default function PromptPage() {
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState('')
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false)
  const [invoiceURL, setInvoiceURL] = useState('')
  const [convoHistory, setConvoHistory] = useState<ConvoHistory[]>([])
  const { user } = useAuth()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  const userEmail = user?.email || ''
  const chatContainerRef = createRef<HTMLDivElement>()
  const [chatSession, setChatSession] = useState<string>('')

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [convoHistory, chatContainerRef])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input.length === 0) return
    const newConvoHistory = [...convoHistory]
    newConvoHistory.push({ name: userEmail, response: input })
    setConvoHistory(newConvoHistory)
    setInput('')
    setLoading(true)

    const askPath = addImpersonationParams(
      '/ask',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(askPath, {
      method: 'POST',
      body: JSON.stringify({
        question: input,
        chat_session: chatSession,
      }),
    })

    if (resp.error) {
      newConvoHistory.push({
        name: 'Diminish AI Assistant',
        response: 'Sorry, I am unable to answer that question',
      })
    } else {
      newConvoHistory.push({
        name: 'Diminish AI Assistant',
        response: resp.message,
        contracts: resp.contracts,
        app_id: resp.app_id,
        app_name: resp.app_name,
      })
    }
    setLoading(false)
    setConvoHistory(newConvoHistory)
    setChatSession(resp.chat_session)
  }

  const downloadChat = () => {
    if (convoHistory.length < 2) return
    const csv = convoHistory.map((msg, i) => {
      if (i > 0 && msg.name === 'Diminish AI Assistant') {
        return `"${convoHistory[i - 1].response}","${msg.response}"`
      }
      return ''
    })
    csv.unshift(['question', 'answer'].join(','))
    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-chat-${new Date().toISOString()}.csv`
    a.click()
  }

  const previewInvoice = (presigned_url: string) => {
    setInvoiceURL(presigned_url)
    setShowPDFPreviewModal(true)
  }

  const onSampleQuestionClick = (question: string) => {
    setInput(question)
  }

  const sampleQuestions = [
    'Give me the contract details of Hubspot in a table?',
    'Give me the marketing spend over the last 90 days?',
    'Can you give me a table of paid marketing tools with total spend and their usage over the last 90 days?',
    'How many licenses do we have for Figma?',
    "What's been my spend last quarter?",
    'When was my last payment for linear?',
    'What are some alternatives to Seamless.ai?',
    'what are low usage apps in a table?',
  ]

  return (
    <PageWrapper>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <HeadingLarge style={{ margin: '16px 0' }} color="textDefault">
            Ask AI
          </HeadingLarge>
          <GhostButton onClick={downloadChat} style={{ padding: '12px' }}>
            <DownloadIcon />
          </GhostButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 28,
        }}
      >
        {convoHistory.length === 0 ? (
          <SampleQuestionsWrapper>
            {sampleQuestions.map((question, index) => (
              <SampleQuestionsCard
                key={index}
                onClick={() => onSampleQuestionClick(question)}
              >
                {question}
              </SampleQuestionsCard>
            ))}
          </SampleQuestionsWrapper>
        ) : (
          <HistoryWrapper ref={chatContainerRef}>
            {convoHistory.map((convo, index) => (
              <Response
                key={index}
                name={convo.name}
                response={convo.response}
                contracts={convo.contracts || []}
                app_name={convo.app_name || ''}
                app_id={convo.app_id || 0}
                previewInvoice={previewInvoice}
              />
            ))}
          </HistoryWrapper>
        )}
        <div>
          {loading ? <Loading /> : null}
          <PromptWrapper onSubmit={handleSubmit}>
            <div style={{ flexGrow: 20 }}>
              <TextField
                placeholder="Ask me anything"
                label={''}
                value={input}
                onChange={(newInput: string) => setInput(newInput)}
              />
            </div>
            <Button
              disabled={input.length === 0}
              type="submit"
              style={{ margin: '4px 0 0 16px' }}
            >
              Send
            </Button>
          </PromptWrapper>
        </div>
      </div>
      {showPDFPreviewModal !== false && (
        <PDFPreviewModal
          onDismiss={() => setShowPDFPreviewModal(false)}
          invoice_url={invoiceURL}
        />
      )}
    </PageWrapper>
  )
}

const ResponseWrapper = styled('div')<{ isDiminish: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 12px;
  background-color: ${({ isDiminish }) =>
    isDiminish ? '' : theme.color.backgroundSubdued};
  border-radius: 8px;
  ${({ isDiminish }) => (!isDiminish ? 'width: 50%;' : 'width: 100%;')};
`

const ResponseContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const ContractWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`

type ContractPreviewProps = {
  app_id: string
  tool_name: string
  presigned_url: string
  end_date: string
}

const ContractCardWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid ${theme.color.textPurple};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.color.backgroundHover};
  }
`

const ActionWrapper = styled('a')`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-start;
`
const ActionCardWrapper = styled('a')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: ${theme.color.textDefault};
  text-decoration: none;
  border: 1px solid ${theme.color.textPurple};
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
  &:hover {
    background-color: ${theme.color.backgroundHover};
  }
`
const ActionCard = ({
  url,
  text,
  Icon,
}: {
  url: string
  text: string
  Icon: ({ style }: { style: CSSProperties }) => JSX.Element
}) => (
  <ActionCardWrapper href={url} target="_blank" style={{ gap: '8px' }}>
    <Icon style={{ height: '24px' }} />
    <span>{text}</span>
  </ActionCardWrapper>
)

const ContractCard = ({
  onClick,
  tool_name,
  end_date,
}: {
  onClick: () => void
  tool_name: string
  end_date: string
}) => (
  <ContractCardWrapper onClick={() => onClick()}>
    <Paper style={{ height: '20px' }} />
    {end_date ? (
      <span>{`View ${tool_name}_${end_date.split('T')[0]} document`}</span>
    ) : (
      <span>{`View ${tool_name} document`}</span>
    )}
  </ContractCardWrapper>
)

const Response = ({
  name,
  response,
  contracts,
  app_name,
  app_id,
  previewInvoice,
}: {
  name: string
  response: string
  contracts: ContractPreviewProps[]
  app_name: string
  app_id: number
  previewInvoice: (presigned_url: string) => void
}) => {
  const isDiminish = name === 'Diminish AI Assistant'

  return (
    <ResponseWrapper isDiminish={isDiminish}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px',
          height: '36px',
        }}
      >
        {isDiminish ? (
          <img
            src={DiminishLogo}
            alt={`${name}-logo`}
            style={{ height: '24px' }}
          />
        ) : (
          <Profile name={name} size="small" />
        )}
      </div>
      <ResponseContainer>
        {isDiminish ? (
          <PromptResponse
            response={response}
            contracts={contracts}
            app_name={app_name}
            app_id={app_id}
            previewInvoice={previewInvoice}
          />
        ) : (
          <div style={{ marginTop: '5px', flexGrow: 10 }}>
            <Markdown>{response}</Markdown>
          </div>
        )}
      </ResponseContainer>
    </ResponseWrapper>
  )
}

export const findMarkdownSections = (response: string): ResponseSection[] => {
  const lines = response.split('\n')
  let start = 0
  let end = 0
  const sections: ResponseSection[] = []
  let type: 'table' | 'text' = 'text'
  for (let i = 0; i < lines.length; i++) {
    end = i
    if (lines[i].startsWith('|')) {
      if (type === 'text') {
        sections.push({
          start,
          end,
          markdown: lines.slice(start, end),
          type,
        })
        start = i
      }
      type = 'table'
    } else {
      if (type === 'table') {
        sections.push({
          start,
          end,
          markdown: lines.slice(start, end),
          type,
        })
        start = i
      }
      type = 'text'
    }
  }
  end = lines.length
  sections.push({
    start,
    end,
    markdown: lines.slice(start, end),
    type,
  })
  return sections
}

const tableToCSV = (response: string): string => {
  const csvTable = []
  if (response) {
    for (let line of response.split('\n')) {
      line = line.replace(/\s+/g, ' ')
      if (line.startsWith('|')) line = line.slice(1)
      if (line.endsWith('|')) line = line.slice(0, -1)
      if (line.includes('-----')) continue

      let row = line.split('|')
      row = row.map((cell) => cell.trim())

      const csvRow = '"' + row.join('","') + '"'
      csvTable.push(csvRow)
    }
  }
  return csvTable.join('\n')
}

type ResponseSection = {
  start: number
  end: number
  markdown: string[]
  type: 'table' | 'text'
}

const PromptResponse = ({
  response,
  contracts,
  app_id,
  app_name,
  previewInvoice,
}: {
  response: string
  contracts: ContractPreviewProps[]
  app_name: string
  app_id: number
  previewInvoice: (presigned_url: string) => void
}) => {
  const [copied, setCopied] = useState(false)
  const [sections, setSections] = useState<ResponseSection[]>([])

  useEffect(() => {
    const s = findMarkdownSections(response)
    setSections(s)
  }, [response])

  const copyResponse = () => {
    navigator.clipboard.writeText(response)
    setCopied(true)
    setTimeout(() => setCopied(false), 4000)
  }
  return (
    <>
      <div style={{ marginTop: '5px', flexGrow: 10 }}>
        {sections.map((section, index) => (
          <div key={index}>
            {section.type === 'table' ? (
              <PromptTable markdownTable={section.markdown} />
            ) : (
              <Markdown>{section.markdown.join('\n')}</Markdown>
            )}
          </div>
        ))}
        {contracts.length > 0 ? (
          <div style={{ marginBottom: '24px' }}>
            If you need more details, feel free to check out the full contract
            below:
          </div>
        ) : null}
      </div>
      <ContractWrapper>
        {contracts.map((contract, index) => (
          <ContractCard
            key={index}
            onClick={() => previewInvoice(contract.presigned_url)}
            tool_name={contract.tool_name}
            end_date={contract.end_date}
          />
        ))}
        {app_id > 0 ? (
          <ActionCard
            url={`/product/${app_id}`}
            text={`Open ${app_name} details page in a new tab`}
            Icon={OpenNewTabLight}
          />
        ) : null}
      </ContractWrapper>
      <ActionWrapper>
        {copied ? (
          <Checkmark />
        ) : (
          <Copy style={{ width: '24px' }} onClick={copyResponse} />
        )}
      </ActionWrapper>
    </>
  )
}

const TableWrapper = styled('div')`
  border-top: 1px solid ${theme.color.border};
  border-left: 1px solid ${theme.color.border};
  border-right: 1px solid ${theme.color.border};
  border-radius: 4px 4px 0 0;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

const PromptTable = ({ markdownTable }: { markdownTable: string[] }) => {
  const [markdown, setMarkdown] = useState(markdownTable)
  const [copiedTable, setCopiedTable] = useState(false)
  const [graphable, setGraphable] = useState(false)
  const [graphData, setGraphData] = useState<GraphData>({
    labels: [],
    datasets: [
      {
        id: 1,
        type: 'bar',
        label: '',
        data: [],
        backgroundColor: theme.color.graphLineBlue,
        hoverBackgroundColor: theme.color.graphLineBlue,
        borderColor: theme.color.graphLineBlue,
        yAxisID: 'y1',
        borderRadius: 4,
        barThickness: 24,
        categoryPercentage: 0.9,
        minBarLength: 0,
      },
    ],
  })
  const [showGraph, setShowGraph] = useState(false)

  useEffect(() => {
    setMarkdown(markdownTable)
  }, [markdownTable])

  const downloadTable = () => {
    setCopiedTable(true)
    const csv = tableToCSV(markdown.join('\n'))
    downloadCSV(csv, 'diminish-ai-table')
    setTimeout(() => setCopiedTable(false), 4000)
  }
  useEffect(() => {
    graph(markdown)
  }, [markdown])

  // TODO: make this more dynamic to support more column types
  const graph = (markdownTable: string[]) => {
    const csv = tableToCSV(markdownTable.join('\n'))
    const lines = csv.split('\n')
    if (lines[0] === '"Tool Name","Total Spend ($)"') {
      const data: { [k: string]: number } = {}
      for (let i = 1; i < lines.length; i++) {
        let line = lines[i].split('","')
        line = line.map((cell) => (cell = cell.replace(/"/g, '')))
        data[line[0]] = parseFloat(line[1].replace(/,/g, ''))
      }
      setGraphData((olData) => ({
        ...olData,
        labels: Object.keys(data),
        datasets: [
          {
            ...olData.datasets[0],
            data: Object.values(data),
            label: 'Total Spend ($)',
          },
        ],
      }))
      setGraphable(true)
    }
  }
  const opt = {
    plugins: {
      legend: {
        display: true,
        align: 'end' as const,
        labels: {
          boxHeight: 24,
          boxWidth: 24,
          useBorderRadius: true,
          borderRadius: 2,
        },
      },
    },
    scales: {
      y1: {
        grid: {
          display: false,
          offset: true,
        },
        position: 'left' as const,
      },
    },
  }

  return (
    <>
      <TableWrapper>
        {graphable && (
          <span
            style={{ cursor: 'pointer', padding: '4px', height: '24px' }}
            onClick={() => setShowGraph(!showGraph)}
          >
            <BarChart color={theme.color.textDefault} />
          </span>
        )}
        {copiedTable ? (
          <Checkmark />
        ) : (
          <span
            className={'download-csv'}
            onClick={downloadTable}
            style={{
              cursor: 'pointer',
              padding: '4px',
              height: '24px',
              color: theme.color.textDefault,
            }}
          >
            <DownloadIcon />
          </span>
        )}
      </TableWrapper>
      {showGraph ? (
        <div
          style={{
            maxWidth: '1123px',
            borderLeft: `1px solid ${theme.color.border}`,
            borderRight: `1px solid ${theme.color.border}`,
            borderBottom: `1px solid ${theme.color.border}`,
            borderRadius: '0 0 4px 4px',
          }}
        >
          <Chart type="bar" options={opt} data={graphData} />
        </div>
      ) : (
        <Markdown>{markdown.join('\n')}</Markdown>
      )}

      <Tooltip
        anchorSelect={'.download-csv'}
        place="top"
        style={{ zIndex: 10 }}
      >
        Download CSV
      </Tooltip>
    </>
  )
}

const downloadCSV = (csv: string, namePrefix: string) => {
  const blob = new Blob([csv], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${namePrefix}-${new Date().toISOString()}.csv`
  a.click()
}
